import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

class DateInputField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value) {
        this.setState({ date: value })
        this.props.setDate(value)
    };

    static InputFormate = {
        DateFormat: 1,
        DateTimeFormate: 2
    };

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {this.props.inputFormat === DateInputField.InputFormate.DateFormat ?
                    <DatePicker
                        className="form-control"
                        inputFormat='DD/MM/YYYY'
                        value={this.state.date}
                        minDate={this.props.min === undefined ? null : this.props.min}
                        maxDate={this.props.max === undefined ? null : this.props.max}
                        onChange={this.handleChange}
                        renderInput={(params) => {
                            if (this.props.label !== undefined) {
                                params.inputProps.value = `${this.props.label} ` + params.inputProps.value;
                            }
                            return <TextField {...params} />;
                        }}
                    /> :
                    <DateTimePicker
                        className="form-control"
                        inputFormat='DD/MM/YYYY HH:mm'
                        value={this.state.date}
                        minDateTime={this.props.min === undefined ? null : this.props.min}
                        maxDateTime={this.props.max === undefined ? null : this.props.max}
                        onChange={this.handleChange}
                        renderInput={(params) => {
                            if (this.props.label !== undefined) {
                                params.inputProps.value = `${this.props.label} ` + params.inputProps.value;
                            }
                            return <TextField {...params} />;
                        }}
                    />
                }
            </LocalizationProvider>
        );
    }
}

export default DateInputField;