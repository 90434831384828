import {webAuth} from "./Auth0";

function AuthService(){
    const removeLocalStorage = () => {
        localStorage.clear();
    }

    const setLocalStorage = (refreshToken, idToken, accessToken, expiresAt) => {
        localStorage.setItem('refresh_token', refreshToken);
        localStorage.setItem('id_token', idToken);
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('expires_at', expiresAt);
    }

    const authenticate = async (result) => {
        const expiresAt = JSON.stringify(result.expiresIn * 1000 + new Date().getTime());

        setLocalStorage(result.refreshToken, result.idToken, result.accessToken, expiresAt);
    }
    
    const isAuthenticated = () => {
        const date = localStorage.getItem('expires_at');
        const refreshToken = localStorage.getItem('refresh_token');
        if (date && refreshToken) {
            const expiresAt = JSON.parse(date);
            if (!refreshToken || (new Date().getTime() > expiresAt)) {
                removeLocalStorage();
                return false;
            }
            return true;
        }
        return false;
    }
    
    const getAccessToken = () => {
        if(isAuthenticated()){
            return localStorage.getItem('access_token');
        }
    }
    
    const loginUser = (email, password, errorCallback) => {
        let databaseConnection = process.env.REACT_APP_AUTH_DB_CONNECTION;
        webAuth.client.login({
            realm: databaseConnection,
            username: email,
            password: password,
        },  async (err, result) => {
            if(err) {
                if(errorCallback) {
                    errorCallback(err.description);
                }
                return;
            }
            
            await authenticate(result);
            window.location.href = "/";
        });
    }
    
    const logoutUser = () => {
        removeLocalStorage();
        window.location.href = "/";
    }
    
    return {
        login: loginUser,
        logout: logoutUser,
        isAuthenticated: isAuthenticated,
        getAccessToken: getAccessToken,
    };
}

export default AuthService;