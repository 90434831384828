import React, {Component} from 'react';
import OTPInput from "otp-input-react";
import {Modal} from "react-bootstrap";
import Icons from "../Common/Icons";
import {Alert, Button, FormControl, FormGroup, Typography} from "@mui/material";
import LoadingCircle from "../Common/LoadingCircle";

class OtpModal extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            otp: '',
            loading: false
        };
        
        this.escFunction = this.escFunction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    close = () => {
        this.setState({ otp: '', loading: false });
        this.props.onEscapeKeyPress();
    }

    async onSubmit(){
        this.setState({loading: true,});
        await this.props.register(this.state.otp);
        this.setState({loading: false,});
    }
    
    render() {
        return (
            <Modal show={this.props.show} size="lg">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Typography variant="h5" align="center">
                        Enter the otp sent to your mobile number
                    </Typography>
                    <FormGroup
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        className={'p-3'}
                    >
                        <FormControl>
                            <OTPInput
                                value={this.state.otp}
                                onChange={(value) => this.setState({otp: value})}
                                autoFocus
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "10px",
                                    padding: "5px"
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            {
                                this.state.loading ? <LoadingCircle width={40} height={40} /> :
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={this.onSubmit}
                                    className={'p-2 m-2'}
                                >
                                    Send
                                </Button>
                            }
                        </FormControl>
                        
                        {
                            this.props.error &&
                            <Alert severity="error">{this.props.error_message}</Alert>
                        }
                    </FormGroup>
                </Modal.Body>
            </Modal>
        );
    }
}

export default OtpModal;