const PermissionTypes =
    {
        canCallBatchesQuery : "acceptance-service:call-batches-query",
        canCallChartsQuery : "acceptance-service:call-charts-query",
        canCallStatisticsQuery : "acceptance-service:call-statistics-query",
        canCallTransactionsQuery : "acceptance-service:call-transactions-query",
        canCallUsersQuery : "acceptance-service:call-users-query",
        canCallUsersMutation : "acceptance-service:call-users-mutation",
        canViewIntegrationGuide: "acceptance-service:view-integration-guide",
        canViewLiveLinks: "acceptance-service:view-live-links",
        canRefund: "acceptance-service:trsansaction-reversal",
    }
export default PermissionTypes;