import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { PaymentMethodNames, TransactionStatus, TransactionStatusNames } from "../../Constants";
import Icons from "../Common/Icons";
import ColorPallete from "../Common/ColorPallete";
import dateFormat from 'dateformat';
import { convertToLocalDate } from '../Common/Utils';

class TransactionDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    close = () => {
        this.props.onEscapeKeyPress();
    }

    renderTransactionStatus = (status) => {
        let color = ColorPallete.grey;
        switch (status) {
            case TransactionStatus.Failed:
                color = ColorPallete.red;
                break;
            case TransactionStatus.Successful:
                color = ColorPallete.lightGreen;
                break;
            case TransactionStatus.InProgress:
                color = ColorPallete.DarkYellow;
                break;
            case TransactionStatus.Authenticated:
                color = ColorPallete.cyan;
                break;
            case TransactionStatus.PendingAuthentication:
                color = ColorPallete.brown;
                break;
            case TransactionStatus.Refunded:
                color = ColorPallete.DarkOrange;
                break;
            default: color = ColorPallete.grey;

        }
        return (<div>
            <span className='dot' style={{ backgroundColor: color }}></span>
            <span className='ml-2'>{TransactionStatusNames[status]}</span>
        </div>)
    }

    formatMetadataKey = (key) => {
        return key.replaceAll('_', ' ').replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    }

    render() {
        return (
            <Modal show={this.props.show} size="xl">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4">
                        <div className="col text-center">
                            <h4><strong>Transaction Details</strong></h4>
                        </div>
                        <br />
                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-lg-2"}>
                                <label className={"col-form-label"}>Amount</label>
                                <input type={"text"} value={this.props.transaction?.paidAmount.toFixed(2) + ' EGP'} className={"form-control"} disabled />
                            </div>
                            <div className='col-lg-1'></div>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>Merchant Ref.</label>
                                <input type={"text"} value={this.props.transaction?.merchantTransactionReference} className={"form-control"} disabled />
                            </div>
                            <div className='col-lg-1'></div>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>System Ref.</label>
                                <input type={"text"} value={this.props.transaction?.systemTransactionReference} className={"form-control"} disabled />
                            </div>
                        </div>
                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-lg-2"}>
                                <label className={"col-form-label"}>Order Status</label>
                                {this.renderTransactionStatus(this.props.transaction?.transactionStatus)}
                            </div>
                            <div className='col-lg-1'></div>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>Payment Method</label>
                                <input type={"text"} className={"form-control"} value={PaymentMethodNames[this.props.transaction?.paymentMethodType]} disabled />
                            </div>
                            <div className='col-lg-1'></div>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>Transaction Time</label>
                                <input type={"text"} className={"form-control"} value={this.props.transaction?.transactionTimeStamp == null ? null : dateFormat(convertToLocalDate(this.props.transaction?.transactionTimeStamp), "mmmm dd, yyyy HH:MM")} disabled />
                            </div>
                        </div>
                        <br />
                        {
                            this.props.transaction?.metadata?.length > 0 ? (<div className="col mt-4 text-center">
                                <h5><strong>Extra Details</strong></h5>
                            </div>) : null
                        }
                        <div className={"form-row mt-4"}>
                            {
                                this.props.transaction?.metadata?.map((data, index) => {
                                    return (<div key={index} className={"form-group col-lg-4"}>
                                        <label className={"col-form-label"}>{this.formatMetadataKey(data.key)}</label>
                                        <input type={"text"} className={"form-control"} value={data.value} disabled />
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>);
    }
}

export default TransactionDetailsModal;