import React, { Component } from 'react';
import Icons from "../Common/Icons";


class QuickSearchBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ searchText: event.target.value })
    };

    render() {
        return (
            <div className='row'>
                <div className='col-lg-6 pt-3 search-block'>
                    <i className='ml-1 search-icon'><Icons.SearchIcon /></i>
                    <input type="text" className='form-control' style={{ border: 'none', padding: '10px', textIndent: '25px' }} placeholder="Quick search by ID" onChange={this.handleChange} />
                </div>
                <div className='pt-3 col-lg-3 pr-4'>
                    <p className='btn btn-block quick-search-text' onClick={() => this.props.onSearchButtonClicked(this.state.searchText)}>Quick Search</p>
                </div>
                <div className='vertical-line col-lg-3'>
                    <div className='advanced-search-text text-center'>
                        <strong onClick={() => this.props.onAdvancedSearchClick()}>Advanced Search</strong>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuickSearchBox;