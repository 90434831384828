export const errors = {
    "LoginRequired": 'login_required',
    "ConsentRequired": 'consent_required',
};

export const PaymentMethodType = {
    "All": null,
    "BankCard": 1,
    "MeezaCard": 2,
    "Wallet": 3,
};

export const PaymentMethodNames = {
    "BANK_CARD": "Bank Card",
    "MEEZA_CARD": "Meeza Card",
    "WALLET": "Wallet"
}

export const TransactionStatusNames = {
    "IN_PROGRESS": "In Progress",
    "SUCCESSFUL": "Successful",
    "FAILED": "Failed",
    "REFUNDED": "Refunded",
    "AUTHENTICATED": "Authenticated",
    "PENDING_AUTHENTICATION": "Pending"
}

export const TransactionStatus = {
    InProgress: "IN_PROGRESS",
    Successful: "SUCCESSFUL",
    Failed: "FAILED",
    Refunded: "REFUNDED",
    Authenticated: "AUTHENTICATED",
    PendingAuthentication: "PENDING_AUTHENTICATION"
}

export const OnboardingState = {
    acceptedReview: "ACCEPTED_REVIEW",
    onboardingFailed: "ONBOARDING_FAILED",
    notInitiated: "NOT_INITIATED",
    onboardingComplete: "ONBOARDING_COMPLETED",
}