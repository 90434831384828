import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import AuthService from "../Auth/AuthService";

function WithAuthenticationRoute({ component: Component, permissions: Permissions, ...args }) {
    const { isAuthenticated, getAccessToken } = AuthService();
    const { DoesUserHaveThePermission } = PermissionProvider();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(
        ()=>{
            const authToken = getAccessToken();
            setAccessToken(authToken);
        } , [getAccessToken]
    );
    
    let isAuthorized = false || Permissions === undefined;
    Permissions?.forEach(permission => isAuthorized = isAuthorized || DoesUserHaveThePermission(permission, accessToken));
    
    return (
        <Route
            {...args}
            render = { props => isAuthenticated() && isAuthorized ? 
                (
                    <Component {...props}/>
                ) : 
                (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default WithAuthenticationRoute;