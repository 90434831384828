import React, { Component } from 'react';
import Chart from "../Components/Charts/Chart";
import GraphQLClient from "../Components/Api/GraphQLClient";
import ChartQuery from "../Components/GraphQLQueries/ChartQuery";
import '../App.css';
import StatisticsCard from "../Components/Charts/StatisticsCard";
import {
    ThisMonthStatisticsQuery,
    ThisWeekStatisticsQuery,
    TodayStatisticsQuery
} from "../Components/GraphQLQueries/StatisticsQuery";
import AuthService from "../Components/Auth/AuthService";
import LogoutButton from '../Components/Auth/LogoutButton';

class Statistics extends Component {

    constructor(props) {
        super(props);

        this.fetchChartData = this.fetchChartData.bind(this);
        this.fetchStatistics = this.fetchStatistics.bind(this);
    }
    fetchChartData(metric, filters, callback) {
        const {getAccessToken} = AuthService();

        const accessToken = getAccessToken();
        
        const graphQlClient = GraphQLClient(accessToken);

        graphQlClient.client.query({
            query: ChartQuery,
            variables: {
                metric: metric,
                payment_method_type: filters.payment_method_type,
                transactions_time_range_start: filters.transactions_time_range_start,
                transactions_time_range_end: filters.transactions_time_range_end
            }
        }).then(result => {
            callback(result);
        });
    }

    fetchStatistics(query, callback){
        const {getAccessToken} = AuthService();

        const accessToken = getAccessToken();
        
        const graphQlClient = GraphQLClient(accessToken);

        graphQlClient.client.query({
            query: query,
        }).then(result => {
            callback(result);
        });
    }

    render() {
        return (
            <div className='p-4'>
                 <div className='mt-4 row'>
                    <div className='col-md-9'>
                    </div>
                    <div className='col-md-3 mt-2 pt-1'>
                        <LogoutButton />
                    </div>
                </div>
                <div className='mt-4 main-box p-4'>
                    <div className='mt-4'>
                        <div className='col ml-2'>
                            <h3><strong>Analytics</strong></h3>
                        </div>
                    </div>
                    <br />
                    <div className="statistics-box row m-4 pt-3 pb-3">
                        <div className='col-4 right-border'>
                            <StatisticsCard title={"Total amount"} subTitle={"today"} query={TodayStatisticsQuery} fetch={this.fetchStatistics} />
                        </div>
                        <div className='col-4 right-border'>
                            <StatisticsCard title={"Total amount"} subTitle={"this week"} query={ThisWeekStatisticsQuery} fetch={this.fetchStatistics} />
                        </div>
                        <div className='col-4'>
                            <StatisticsCard title={"Total amount"} subTitle={"this month"} query={ThisMonthStatisticsQuery} fetch={this.fetchStatistics} />
                        </div>
                    </div>
                    <br />
                    <div className='mt-2 statistics-box'>
                        <Chart metric={1} fetch={this.fetchChartData} />
                    </div>
                    <div className='mt-4 statistics-box'>
                        <Chart metric={2} fetch={this.fetchChartData} />
                    </div>
                    <div className='mt-4 statistics-box'>
                        <Chart metric={3} fetch={this.fetchChartData} />
                    </div>
                </div>
            </div>
        );
    }
}
export default Statistics;