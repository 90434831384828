import React, { Component } from 'react';
import GraphQLClient from "../Components/Api/GraphQLClient";
import TransactionsQuery from "../Components/GraphQLQueries/TransactionsQuery";
import TransactionsFiltersModal from "../Components/Transactions/TransactionsFiltersModal";
import { DataGrid } from '@mui/x-data-grid';
import dateFormat from 'dateformat';
import { PaymentMethodNames, TransactionStatus, TransactionStatusNames } from '../Constants';
import QuickSearchBox from '../Components/Common/QuickSearchBox';
import LogoutButton from '../Components/Auth/LogoutButton';
import { CSVLink } from 'react-csv';
import LoadingCircle from '../Components/Common/LoadingCircle';
import ColorPallete from '../Components/Common/ColorPallete';
import AuthService from "../Components/Auth/AuthService";
import ConfirmationModal from '../Components/Common/ConfirmationModal';
import ApiClient from '../Components/Api/ApiClient';
import TransactionDetailsModal from '../Components/Transactions/TransactionDetailsModal';
import PermissionProvider from "../Components/PermissionProvider/PermissionProvider";
import PermissionTypes from "../Components/PermissionProvider/PermissionTypes";
import { Link } from 'react-router-dom';
import { convertToLocalDate } from '../Components/Common/Utils';

const { refund, getCredentials } = ApiClient();
class Transactions extends Component {
    constructor(props) {
        super(props);

        this.graphQlClient = null;

        this.state = {
            dataToBeExported: [],
            transactions: [],
            totalNumberOfTransactions: 0,
            page: 0,
            sizePerPage: 10,
            showFiltersModal: false,
            showConfirmationModal: false,
            loading: true,
            showTransactionDetailsModal: false,
            isRefundButtonEnabled: false,
            isAuthorizedToRefund: false,
        };

        this.filters = {
            offset: 0,
            limit: this.state.sizePerPage,
            customer_id: null,
            merchant_transaction_reference: null,
            system_transaction_reference: null,
            payment_method_type: null,
            transaction_status_list: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        }

        this.loadData = this.loadData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
        this.handleQuickSearchButtonClicked = this.handleQuickSearchButtonClicked.bind(this);
        this.handleAdvancedSearchClick = this.handleAdvancedSearchClick.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.hideFiltersSection = this.hideFiltersSection.bind(this);
        this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
        this.onRefundSuccess = this.onRefundSuccess.bind(this);
        this.onRefundConfirm = this.onRefundConfirm.bind(this);
    }

    clearFilters() {
        this.filters = {
            offset: 0,
            limit: this.state.sizePerPage,
            customer_id: null,
            merchant_transaction_reference: null,
            system_transaction_reference: null,
            payment_method_type: null,
            transaction_status_list: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        }
    }

    componentDidMount() {
        const { getAccessToken } = AuthService();
        const { DoesUserHaveThePermission } = PermissionProvider();

        const accessToken = getAccessToken();
        this.authToken = accessToken;

        if (DoesUserHaveThePermission(PermissionTypes.canRefund, accessToken) === true) {
            this.setState({ isAuthorizedToRefund: true });
            this.getAuthCredentails();
        }

        this.graphQlClient = GraphQLClient(accessToken);
        this.loadData(0, this.state.sizePerPage);
    }

    getAuthCredentails() {
        getCredentials(this.authToken, (credentialsResponse) => {
            if (credentialsResponse && credentialsResponse.succeeded) {
                if (credentialsResponse.credentials) {
                    this.requestHashKey = credentialsResponse.credentials.request_hash_key;
                    this.setState({ isRefundButtonEnabled: true });
                }
            }
        }, (error) => console.log(error));
    }

    loadData(page, sizePerPage) {
        this.filters = {
            ...this.filters,
            offset: page * sizePerPage,
            limit: sizePerPage,
        }

        this.setState({ loading: true });
        this.graphQlClient.client.query({
            query: TransactionsQuery,
            variables: this.filters,
        }).then(result => {
            this.error = result.error;
            this.setState({
                loading: result.loading,
                page: page,
                sizePerPage: sizePerPage,
                transactions: result.data.transactionsPage.transactions,
                totalNumberOfTransactions: result.data.transactionsPage.totalNumberOfTransactions,
            });
        });
    }

    loadDataToBeExported() {
        const exportingFilters = {
            ...this.filters,
            offset: 0,
            limit: null,
        };

        this.setState({ loading: true });
        this.graphQlClient.client.query({
            query: TransactionsQuery,
            variables: exportingFilters,
        }).then(result => {
            this.error = result.error;
            this.setState({
                loading: result.loading, dataToBeExported: result.data.transactionsPage.transactions
                    .map(transaction => {
                        const updatedTransaction = {
                            ...transaction,
                            transactionTimeStamp: dateFormat(convertToLocalDate(transaction.transactionTimeStamp), "mmmm dd, yyyy HH:MM"), // Update the timestamp
                        };
                        return updatedTransaction;
                    })
            });
            document.getElementById('csv-link').click();
        });
    }

    handlePageChange(newPage) {
        setTimeout(() => {
            this.loadData(newPage, this.state.sizePerPage);
        }, 100);
    }

    handlePageSizeChange(newPageSize) {
        this.setState({ sizePerPage: newPageSize })
        this.loadData(0, newPageSize);
    }

    handleSearchButtonClick(filters) {
        this.filters = {
            ...this.filters,
            ...filters,
        }
        this.loadData(0, this.state.sizePerPage);
        this.hideFiltersSection();
    }

    hideFiltersSection() {
        this.setState({ showFiltersModal: false });
    }

    hideConfirmationModal() {
        this.setState({ showConfirmationModal: false })
    }

    hideTransactionDetailsModal = () => {
        this.setState({ showTransactionDetailsModal: false });
    }

    loadRows() {
        return this.state.transactions.map((row, index) => { return { id: index, ...row } });
    }

    handleQuickSearchButtonClicked(searchText) {
        this.clearFilters();
        this.filters.merchant_transaction_reference = searchText;
        this.handleSearchButtonClick({});
    }

    handleAdvancedSearchClick() {
        this.setState({
            showFiltersModal: !this.state.showFiltersModal,
        });
    }

    renderTransactionStatus(status) {
        let color = ColorPallete.grey;
        switch (status) {
            case TransactionStatus.Failed:
                color = ColorPallete.red;
                break;
            case TransactionStatus.Successful:
                color = ColorPallete.lightGreen;
                break;
            case TransactionStatus.InProgress:
                color = ColorPallete.DarkYellow;
                break;
            case TransactionStatus.Authenticated:
                color = ColorPallete.cyan;
                break;
            case TransactionStatus.PendingAuthentication:
                color = ColorPallete.brown;
                break;
            case TransactionStatus.Refunded:
                color = ColorPallete.DarkOrange;
                break;
            default: color = ColorPallete.grey;

        }
        return (<div>
            <span className='dot' style={{ backgroundColor: color }}></span>
            <span className='ml-2'>{TransactionStatusNames[status]}</span>
        </div>)
    }

    renderHeader(headerName) {
        return (
            <div className='header-hover mt-3'>
                <p>{headerName}</p>
            </div>
        );
    }

    getDataToBeExported() {
        let data = JSON.parse(JSON.stringify(this.state.dataToBeExported));
        data.forEach(row => {
            delete row['__typename'];
            delete row['isPaymentLinkGenerationTransaction'];
            delete row['metadata'];
        });

        return data;
    }

    onRefundButtonClicked(merchantTransactionReference) {
        this.setState({ showConfirmationModal: true });
        this.refundTransactionReference = merchantTransactionReference;
    }

    onViewButtonClicked = (transaction) => {
        this.transaction = transaction;
        this.setState({ showTransactionDetailsModal: true });
    }

    async onRefundConfirm() {
        this.hideConfirmationModal();

        const request = {
            merchant_transaction_reference: this.refundTransactionReference,
        };

        try {
            await refund(this.authToken, request, this.requestHashKey, this.onRefundSuccess, this.onError);
        } catch (error) {
            this.onError(error)
        }
    }

    onRefundSuccess(refundResponse) {
        if (refundResponse && refundResponse.succeeded) {
            if (refundResponse.response.transaction_status === TransactionStatusNames.REFUNDED) {
                alert(`Transaction refunded successfully`);
                this.loadData(0, this.state.sizePerPage);
            } else {
                alert('Transaction is not refunded!')
            }
        } else if (refundResponse && refundResponse.error_message) {
            alert(refundResponse.error_message);
        } else {
            alert("Failed to refund. Something went wrong!");
        }
        this.refundTransactionReference = null;
    }

    onError = (error) => {
        console.log(error);
        alert(error.message);
    }

    renderRefundButton(merchantTransactionReference) {
        return (
            <div className='text-center ml-auto'>
                <button disabled={!this.state.isRefundButtonEnabled} className='btn btn-sm' style={{ background: ColorPallete.DarkOrange, color: ColorPallete.white }} onClick={() => this.onRefundButtonClicked(merchantTransactionReference)}>Refund</button>
            </div>);
    }

    renderViewButton = (transaction) => {
        return (
            <div className='text-center ml-auto'>
                <button className='btn btn-sm btn-info' onClick={() => this.onViewButtonClicked(transaction)}>View</button>
            </div>);
    }

    render() {
        return (
            <div className={"p-4"}>
                <div className='mt-4 row'>
                    <div className='col-9'>
                        <div className='main-box pl-2'>
                            <QuickSearchBox onSearchButtonClicked={this.handleQuickSearchButtonClicked} onAdvancedSearchClick={this.handleAdvancedSearchClick} />
                        </div>
                    </div>
                    <div className='col-3 mt-2 pt-1'>
                        <LogoutButton />
                    </div>
                </div>

                <TransactionsFiltersModal handleSearchButtonClick={this.handleSearchButtonClick} show={this.state.showFiltersModal} onEscapeKeyPress={this.hideFiltersSection} />

                {
                    this.state.loading ? <div className='mt-20'><LoadingCircle /></div>
                        : <div className={"mt-4 main-box p-4"}>
                            <div className="row pt-2">
                                <div className="col-12 row">
                                    <div className='col'>
                                        <h4><strong>Search Result</strong></h4>
                                    </div>
                                    <div className='ml-auto'>
                                        <Link className="primary-color" onClick={() => this.loadDataToBeExported()}>Export as CSV</Link>
                                        <CSVLink id='csv-link' className="primary-color" data={this.getDataToBeExported()} hidden filename='transactions.csv'>Export as CSV</CSVLink>
                                    </div>
                                </div>
                            </div>
                            {
                                !(this.state.loading || this.error) &&
                                <DataGrid
                                    className='main-box mt-4'
                                    rowCount={this.state.totalNumberOfTransactions}
                                    pageSize={this.state.sizePerPage}
                                    page={this.state.page}
                                    onPageChange={this.handlePageChange}
                                    onPageSizeChange={this.handlePageSizeChange}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    pagination
                                    autoHeight
                                    rows={this.loadRows()}
                                    paginationMode={'server'}
                                    columns={[
                                        { field: 'customerId', headerName: 'Customer ID', width: 160, renderHeader: (_) => this.renderHeader('Customer ID'), },
                                        { field: 'merchantTransactionReference', headerName: 'Merchant Ref.', width: 200, renderHeader: (_) => this.renderHeader('Merchant Ref.') },
                                        { field: 'systemTransactionReference', headerName: 'System Ref.', width: 200, renderHeader: (_) => this.renderHeader('System Ref.') },
                                        { field: 'paidAmount', headerName: 'Amount', width: 130, renderHeader: (_) => this.renderHeader('Amount'), renderCell: (param) => param.value.toFixed(2), },
                                        { field: 'paymentMethodType', headerName: 'Payment Method', width: 180, renderHeader: (_) => this.renderHeader('Payment Method'), renderCell: (param) => PaymentMethodNames[param.value] },
                                        {
                                            field: 'transactionStatus', headerName: 'Status', width: 130, renderHeader: (_) => this.renderHeader('Status'), renderCell: (param) => {
                                                return this.renderTransactionStatus(param.value);
                                            }
                                        },
                                        { field: 'transactionTimeStamp', headerName: 'Time', width: 200, renderHeader: (_) => this.renderHeader('Time'), renderCell: (param) => dateFormat(convertToLocalDate(param.value), "mmmm dd, yyyy HH:MM") },
                                        { field: 'gatewayMessage', headerName: 'Gateway Message', width: 200, renderHeader: (_) => this.renderHeader('Gateway Message') },
                                        { field: 'gatewayDecision', headerName: 'Gateway Decision', width: 200, renderHeader: (_) => this.renderHeader('Gateway Decision') },
                                        { field: 'gatewayResponseCode', headerName: 'Gateway Response Code', width: 200, renderHeader: (_) => this.renderHeader('Gateway Response Code') },
                                        {
                                            field: 'refund', headerName: '', width: 130, renderHeader: (_) => this.renderHeader(''), renderCell: (param) => {
                                                return param.row['transactionStatus'] === TransactionStatus.Successful && this.state.isAuthorizedToRefund === true ? this.renderRefundButton(param.row['merchantTransactionReference']) : null;
                                            }
                                        },
                                        {
                                            field: 'view', headerName: '', width: 130, renderHeader: (_) => this.renderHeader(''), renderCell: (param) => {
                                                return this.renderViewButton(param.row);
                                            }
                                        },
                                    ]} />
                            }
                        </div>
                }
                <ConfirmationModal
                    message={`Do you want to refund transaction ${this.refundTransactionReference}?`}
                    show={this.state.showConfirmationModal}
                    onEscapeKeyPress={this.hideConfirmationModal}
                    onConfirm={this.onRefundConfirm} />
                <TransactionDetailsModal show={this.state.showTransactionDetailsModal} transaction={this.transaction} onEscapeKeyPress={this.hideTransactionDetailsModal} />
            </div>
        );
    }
}

export default Transactions;