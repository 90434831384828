import React from "react";
import { Modal } from 'react-bootstrap'
import Icons from "../Common/Icons";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class GenerateLiveLinkModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProducts: [],
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleChange = (selected) => {
        this.setState({
            selectedProducts: selected
        });
    };

    close = () => {
        this.resetState();
        this.props.onEscapeKeyPress();
    }

    resetState = () => {
        this.setState({
            selectedProducts: [],
        });
    }

    onGenerateLink = () => {
        this.props.onGenerateLink({ productIds: this.state.selectedProducts.map(product => product.value) });
    }

    getProductOptions = () => {
        const productOptions = this.props.products.map(product => {
            return { value: product.id, label: `(${product.name} ${product.price} EGP)` }
        });

        return productOptions;
    }

    renderProductOptions = () => {
        return (<ReactSelect
            options={this.getProductOptions()}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option
            }}
            onChange={this.handleChange}
            allowSelectAll={true}
            value={this.state.selectedProducts}
        />);
    }

    render() {
        return (
            <Modal show={this.props.show} size="md">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 p-4">

                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-12"}>
                                <label className={"col-form-label"}>Products</label>
                                {this.renderProductOptions()}
                            </div>
                        </div>
                        <br />
                        <div className={"mt-4 text-center mb-2"}>
                            <button
                                onClick={() => this.onGenerateLink()}
                                className="text-center btn btn-primary"> Generate Link
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }
}

export default GenerateLiveLinkModal;