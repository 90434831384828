import React, {Component} from "react";
import {Alert, Container, FormControl, FormGroup, Link, TextField} from "@mui/material";
import {Button} from "react-bootstrap";
import AuthService from "./AuthService";
import LoadingCircle from "../Common/LoadingCircle";

class LoginForm extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            password: '',
            error: false,
            error_message: '',
            loading: false,
        };
        
        this.login = this.login.bind(this);
        this.setError = this.setError.bind(this);
    }
    
    setError(error_message){
        this.setState({ error: true, error_message: error_message });
    }
    
    login(e){
        e.preventDefault();
        this.setState( { loading: true });
        
        const { login } = AuthService();
        login(this.state.email, this.state.password, this.setError);
        
        this.setState( { loading: false });
    }
    
    render() {
        return <Container sx={{margin: '10%'}}>
            <h4>Login</h4>
            <FormGroup>
                <FormControl margin={"normal"}>
                    <TextField
                        id="email"
                        label="Email Address"
                        variant="outlined"
                        value={this.state.email}
                        onChange={(e) => this.setState({email: e.target.value})}
                    />
                </FormControl>

                <FormControl margin={"normal"}>
                    <TextField
                        id="password"
                        label="Password"
                        type={"password"}
                        variant="outlined"
                        value={this.state.password}
                        onChange={(e) => this.setState({password: e.target.value})}
                    />
                </FormControl>

                <FormControl margin={"normal"}>
                    {
                        this.state.loading ? <LoadingCircle height={40} width={40} /> :
                        <Button onClick={this.login} sx={{width: '60%', align: 'center'}}>Login</Button>
                    }
                </FormControl>
                <FormControl margin={"normal"}>
                    <Link href={"/signup"} textAlign={"center"}>don't have an account?</Link>
                </FormControl>
            </FormGroup>
            {
                this.state.error &&
                <Alert severity="error">{this.state.error_message}</Alert>
            }
        </Container>
    }
}

export default LoginForm;