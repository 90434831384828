import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import AuthService from "../Auth/AuthService";

function WithAuthorizedComponent({ component: Component, permissions: Permissions, props }) {
    const { isAuthenticated, getAccessToken } = AuthService();
    const { DoesUserHaveThePermission } = PermissionProvider();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(
        () => {
            const authToken = getAccessToken();
            setAccessToken(authToken);
        }, [getAccessToken]
    );

    let isAuthorized = false;
    Permissions.forEach(permission => isAuthorized = isAuthorized || DoesUserHaveThePermission(permission, accessToken));
    return (isAuthenticated && isAuthorized ?
        (
            <Component {...props} />
        ) :
        (
            <Redirect
                to={{
                    pathname: "/",
                }}
            />
        )
    );
}

export default WithAuthorizedComponent;