export const IntegrationGuideData = {
    reactNativeInstallDependencies: `npm i react-native-webview react-native-svg --save`,
    reactNativeInstallPackage(packageName) { return `npm i ${packageName} --save`; },
    reactNativeUsage(packageName, componentName) {
        return `import ${componentName} from '${packageName}';
import { Stage } from '${packageName}/Common/Constants'; 
    
<View style={styles.container}>
      // for saving card checkout
      {
        ${componentName}.SavePaymentCardCheckout({
            sessionToken: "sessionToken", // The session token for the session which you initiated before through our APIs; check our API integration documentation for initiating saving card session
            apiKey: "apiKey", // API Key; you can find it at settings page under API Integration section
          stage: Stage.Sandbox or Stage.Production,
          style: { mainColor: '#000', buttonBorderRadius: 100, inputFieldBorderRadius: 100 },
          onSuccess: (res) => console.log('success', res),
          onFailure: (res) => console.log('failure', res),
          onError: (error) => console.log('error', error)
        }
        )
      }

      // for payment checkout
      {
        ${componentName}.PaymentCheckout({
          sessionToken: "sessionToken", // The session token for the session which you initiated before through our APIs; check our API integration documentation for initiating transaction session
          apiKey: "apiKey", // API Key; you can find it at settings page under API Integration section
          stage: Stage.Sandbox or Stage.Production,
          style: { mainColor: '#000', buttonBorderRadius: 100, inputFieldBorderRadius: 100 },
          onSuccess: (res) => console.log('success', res),
          onFailure: (res) => console.log('failure', res),
          onError: (error) => console.log('error', error)
        }
        )
      }
</View>`;
    },
    getAnAccessTokenCurl(baseDomain) {
        return `curl --location --request POST '${baseDomain}/auth/v1/token' \\
    --header 'x-api-key: {your_api_key: you can find it at settings page under API Integration section}' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
        "grant_type": "password",
        "username": "{username: you can find it at settings page under API Integration section}", 
        "password": "{password: you can find it at settings page under API Integration section}"
    }'`
    },
    getAnAccessTokenResponse: `{
    "access_token": "access token",
    "expires_in": 7200,
    "token_type": "Bearer"
}

access_token: the bearer token used for authentication with any api.
expires_in: when will this token expire (in seconds)? You are responsible to keep your token up to date`,
    requestHashText: `The request should be hashed using HMACSHA256 with a privately shared key. 
The result should be set in a header “request-hash” in hexadecimal format.
Sample Hashing Code:
    string payload;
    string baseUrl;
    string key; // privately shared key
    using (var httpContent = new StringContent(payload, Encoding.UTF8, "application/json"))
    {
        using (var reqMsg = new HttpRequestMessage(HttpMethod.Post, baseUrl)) {
            reqMsg.Content = httpContent;
            using (var hmacSHA256 = new HMACSHA256(Encoding.UTF8.GetBytes(key))) {
                var hash = hmacSHA256.ComputeHash(Encoding.UTF8.GetBytes(payload));
                var hashHex = BitConverter.ToString(hashByte).Replace("-", ""); 
                reqMsg.Headers.Add("request-hash", hashHex);
                await this.httpClient.SendAsync(reqMsg);
            } 
        }
    }`,
    initTransactionSessionCurl(baseDomain) {
        return `curl --location --request POST '${baseDomain}/api/v2/acceptance/transaction/session' \\
    --header 'x-api-key: {your_api_key: you can find it at settings page under API Integration section}' \\
    --header 'request-hash: {your_request_hash: check step 2}' \\
    --header 'Authorization: Bearer {your_access_token: The access token which you get from step 1}' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
        "request_id": "e1248c13-8772-4ec4-8272-a57ec9608578",
        "client_timestamp_utc": "2022-08-09T04:24:02.911382+02:00",
        "request_body": {
            "customer_id": 1,
            "success_url": "http://success.com",
            "failure_url": "https://failure.com",
            "transaction_reference": "test-01",
            "paid_amount": 10,
            "metadata": {
                "key": "value"
            }
        }
    }'`
    },
    initTransactionSessionResponse: `{
    "request_id": "e1248c13-8772-4ec4-8272-a57ec9608578",
    "server_timestamp_utc": "2023-03-26T19:55:36.3951532Z",
    "response": {
        "fees": {
            "total": 1.50,
            "account": 20093
        },
        "session_id": "2090",
        "session_url": "https://sandbox-checkout.sahlpay.app/hosted/payment?token=[token]&session_id=2090",
        "is_3ds_required": true
    },
    "succeeded": true,
    "error_code": null,
    "error_message": null
}

session_url: The url of the payment checkout that you will redirect your customers to it.
You need to replace "[token]" with the customer token which you will get it from step 4`,
    getCustomerTokenCurl(baseDomain) {
        return `curl --location --request POST '${baseDomain}/auth/v1/token/scoped' \\
    --header 'x-api-key: {your_api_key: you can find it at settings page under API Integration section}' \\
    --header 'Authorization: Bearer {your_access_token: The access token which you get from step 1}' \\
    --header 'Content-Type: application/json' \\
    --data-raw '{
        "customer_id": "1"
    }'`
    },
    getCustomerTokenResponse: `{
    "token": "token",
    "error_code": null,
    "error_message": null,
    "succeeded": true
}

token: The customer token that you can use it to replace the "[token]" of the session_url that you got from step 3.`,
};