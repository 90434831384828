import React, {Component} from 'react';

class Credentials extends Component {
    render() {
        return (
            <div className={"form-row mt-4"}>
                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>API Email</label>
                    <input type={"text"} className={"form-control"} value={this.props.credentials.api_user_email} disabled />
                </div>
                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>API Password</label>
                    <input type={"text"} className={"form-control"} value={this.props.credentials.api_user_password} disabled />
                </div>
                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>API Key</label>
                    <input type={"text"} className={"form-control"} value={this.props.credentials.api_key} disabled />
                </div>
                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>Request Hash Key</label>
                    <input type={"text"} className={"form-control"} value={this.props.credentials.request_hash_key} disabled />
                </div>
                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>Webhook Notification Key</label>
                    <input type={"text"} className={"form-control"} value={this.props.credentials.webhook_notification_key} disabled />
                </div>
            </div>
        );
    }
}

export default Credentials;