import { Box, Button } from "@mui/material";
import React from "react";
import { Modal } from 'react-bootstrap'
import Icons from "../Common/Icons";


class AddProductModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            description: null,
            price: null,
            image: null,
        };

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleChange = (event, property) => {
        this.setState({
            [property]: event.target.value,
        });
    }

    handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            this.setState({ image: e.target.files[0] });
        }
    }

    close = () => {
        this.resetState();
        this.props.onEscapeKeyPress();
    }

    resetState = () => {
        this.setState({
            name: null,
            description: null,
            price: null,
            image: null,
        });
    }

    onAddProduct = () => {
        this.props.onAddProduct(this.state);
        this.resetState();
    }

    render() {
        return (
            <Modal show={this.props.show} size="xl">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 p-4">
                        <div className="form-row">
                            <div className="ml-auto">
                                <button
                                    onClick={() => this.onAddProduct()}
                                    className="btn btn-primary"> Add Product
                                </button>
                            </div>
                        </div>

                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>Name</label>
                                <input type={"text"} maxLength={50} className={"form-control"} required onChange={(event => this.handleChange(event, 'name'))} />
                            </div>
                            <div className="col-lg-1"></div>
                            <div className={"form-group col-lg-7"}>
                                <label className={"col-form-label"}>Description</label>
                                <textarea maxLength={300} className={"form-control"} required onChange={(event => this.handleChange(event, 'description'))} />
                            </div>
                        </div>
                        <div className={"form-row mt-2"}>
                            <div className={"form-group col-lg-4"}>
                                <label className={"col-form-label"}>Price</label>
                                <input type={"number"} className={"form-control"} required onChange={(event => this.handleChange(event, 'price'))} />
                            </div>
                            <div className="col-lg-1"></div>
                            <div className={"form-group col-lg-3"}>
                                <Box sx={{ display: "flex", mt: 4 }}>
                                    <label className="mt-2" htmlFor={"image_id"}>
                                        <Button variant={"outlined"} component={"span"}>
                                            Upload Image
                                        </Button>
                                    </label>
                                    <input
                                        id={"image_id"}
                                        type={"file"}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={(event) => this.handleImageChange(event)}
                                    />
                                </Box>
                            </div>
                            <div className="form-group col-lg-4">
                                {this.state.image && <img className='form-control mt-3' style={{ maxHeight: 300, maxWidth: 300, width: 'auto', height: 'auto' }} src={URL.createObjectURL(this.state.image)} alt={"Product"} />}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AddProductModal;