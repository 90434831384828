import { gql } from '@apollo/client';

const ChartQuery = gql
        `query ChartQuery(  
            $metric: Int,
            $payment_method_type: Int,
            $transactions_time_range_start: DateTime,
            $transactions_time_range_end: DateTime
            ){
            chart(
                metric: $metric,
                payment_method_type: $payment_method_type,
                transactions_time_range_start: $transactions_time_range_start,
                transactions_time_range_end: $transactions_time_range_end
                ){
                chartData{
                  dataPointDate
                  dataPointValue
                }
            }
        }`;

export default ChartQuery;