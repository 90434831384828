import React, { Component } from 'react';
import ApiClient from '../Components/Api/ApiClient';
import AuthService from '../Components/Auth/AuthService';
import LoadingCircle from '../Components/Common/LoadingCircle';
import { isValidURL } from '../Components/Common/Utils';
import GeneratedLiveLinks from '../Components/LiveLinks/GeneratedLiveLinks';
import LiveLinkProducts from '../Components/LiveLinks/LiveLinkProducts';

const { getAccessToken } = AuthService();
const { getLiveLinksProfile, getLiveLinkProducts, getLiveLinks, updateLiveLinksProfile } = ApiClient();

class LiveLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingProducts: true,
            loadingLinks: true,
            profileName: '',
            profileWebsite: '',
            isValidProfileWebsite: true,
            isValidProfileName: true,
            products: [],
            links: [],
        }
    }

    async componentDidMount() {
        const accessToken = getAccessToken();
        await getLiveLinksProfile(accessToken, this.onGetProfileSuccess, this.ApiFailureCallback);
        await getLiveLinkProducts(accessToken, this.onGetProductsSuccess, this.ApiFailureCallback);
        await getLiveLinks(accessToken, this.onGetLiveLinksSuccess, this.ApiFailureCallback);
    }

    onGetLiveLinksSuccess = (responseDto) => {
        if (responseDto && responseDto.succeeded === true) {
            this.setState({ links: responseDto.response.payment_links })
        } else {
            // todo: handle errors
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
        this.setState({ loadingLinks: false });
    }

    onGetProductsSuccess = (responseDto) => {
        if (responseDto && responseDto.succeeded === true) {
            this.setState({ products: responseDto.response.products })
        } else {
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
        this.setState({ loadingProducts: false });
    }

    onGetProfileSuccess = (responseDto) => {
        if (responseDto && responseDto.succeeded === true) {
            this.setState({ profileName: responseDto.response.name, profileWebsite: responseDto.response.website_url })
        } else {
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
    }

    formatProfileName = (profileName) => {
        return profileName.replace(/ /g, '')
            .replace(/\//g, '')
            .replace(/\\/g, '');
    }

    handleProfileNameChange = (event) => {
        this.setState({
            profileName: this.formatProfileName(event.target.value)
        });
    }

    handleProfileWebsiteChange = (event) => {
        this.setState({
            profileWebsite: event.target.value,
        });
    }

    validateProfileName = () => {
        const valid = this.state.profileName.length > 0;
        this.setState({ isValidProfileName: valid });
        return valid;
    }

    validateProfileWebsite = () => {
        const valid = isValidURL(this.state.profileWebsite);
        this.setState({ isValidProfileWebsite: valid });
        return valid;
    }

    validateProfile = () => {
        const validProfileName = this.validateProfileName();
        const validProfileWebsite = this.validateProfileWebsite();
        console.log(validProfileName, validProfileWebsite);
        return validProfileName && validProfileWebsite;
    }

    updateProfile = async () => {
        if (this.validateProfile()) {
            const accessToken = getAccessToken();
            await updateLiveLinksProfile(accessToken, { name: this.state.profileName, website_url: this.state.profileWebsite }, this.onUpdateProfileSuccess, this.ApiFailureCallback)
        }
    }

    onUpdateProfileSuccess = async (response) => {
        if (response && response.succeeded === true) {
            alert('Profile updated successfully');
            const accessToken = getAccessToken();
            await getLiveLinks(accessToken, this.onGetLiveLinksSuccess, this.ApiFailureCallback);
        } else {
            console.log(response.error_message);
            alert(response.error_message);
        }
    }

    onLiveLinkUpdated = (updatedLiveLink) => {
        let links = this.state.links.map(link => {
            if (updatedLiveLink.order_id === link.order_id) {
                link = updatedLiveLink;
            }

            return link;
        });
        this.setState({ links: links })
    }

    ApiFailureCallback = (error) => {
        console.log(error);
        alert(`Unexpected error`);
    }

    getInputClassName = (valid) => {
        return valid ? 'form-control' : 'form-control error-border';
    }

    renderProfileSection() {
        return (
            <div className='mt-4 pt-4 ml-2'>
                <div className='col-12'>
                    <h4 className='title-color'>Profile</h4>
                </div>
                <br />
                <div className='col-lg-12'>
                    <div className={"form-row mt-4"}>
                        <div className={"form-group col-lg-4"}>
                            <label className={"col-form-label"}>Name</label>
                            <input type={"text"} value={this.state.profileName} maxLength={50} className={this.getInputClassName(this.state.isValidProfileName)} placeholder="Unique name" onChange={(event) => this.handleProfileNameChange(event)} />
                        </div>
                        <div className={"form-group col-lg-5 ml-2"}>
                            <label className={"col-form-label"}>Website Link</label>
                            <input type={"url"} value={this.state.profileWebsite} maxLength={50} className={this.getInputClassName(this.state.isValidProfileWebsite)} placeholder="Website link" onChange={(event) => this.handleProfileWebsiteChange(event)} />
                        </div>
                        <div className={"form-group col-lg-2 ml-2"}>
                            <label className={"col-form-label"}></label>
                            <button className={"btn btn-block mt-3 btn-primary"} onClick={() => this.updateProfile()}>Update</button>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        );
    }

    onAddProduct = (product) => {
        this.setState({ products: [product, ...this.state.products] });
    }

    onGenerateLink = (link) => {
        this.setState({ links: [link, ...this.state.links] });
    }

    render() {
        return (
            <div className='mt-4 main-box p-4'>
                <div className='mt-4 pb-4'>
                    <div className='col ml-2'>
                        <h2><strong>Live Links</strong></h2>
                    </div>
                </div>
                <hr />
                {this.renderProfileSection()}
                <br />
                <hr />
                {
                    this.state.loadingLinks ? <LoadingCircle height={40} width={40} /> :
                        <GeneratedLiveLinks links={this.state.links} products={this.state.products} onGenerateLink={this.onGenerateLink} onLiveLinkUpdated={this.onLiveLinkUpdated} />
                }
                <br />
                <hr />
                {
                    this.state.loadingProducts ? <LoadingCircle height={40} width={40} /> :
                        <LiveLinkProducts products={this.state.products} onAddProduct={this.onAddProduct} />
                }
                <br />
                <br />
            </div>
        );
    }
}

export default LiveLinks;