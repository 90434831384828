import PermissionTypes from "./PermissionTypes";

const RolePermissions =
    {
        statisticsPagePermissions: [PermissionTypes.canCallStatisticsQuery, PermissionTypes.canCallChartsQuery],
        transactionsPagePermissions: [PermissionTypes.canCallTransactionsQuery],
        transfersPagePermissions: [PermissionTypes.canCallBatchesQuery],
        settingsPagePermissions: [PermissionTypes.canCallUsersQuery, PermissionTypes.canCallUsersMutation],
        integrationGuidePermissions: [PermissionTypes.canViewIntegrationGuide],
        liveLinksPermissions: [PermissionTypes.canViewLiveLinks],
    }
export default RolePermissions;