import React, {Component} from 'react';
import ImageUpload from "./ImageUpload";

class TaxCardImageUpload extends Component {
    render() {
        return (
            <ImageUpload {...this.props} />
        );
    }
}

export default TaxCardImageUpload;