import Statistics from "./Statistics";
import RolePermissions from "../Components/PermissionProvider/RolePermissions";
import WithAuthorizedComponent from "../Components/Common/WithAuthorizedComponent";
import AuthService from "../Components/Auth/AuthService";
import {Redirect} from "react-router-dom";


function Home() {
    const { isAuthenticated } = AuthService();
    return isAuthenticated() ?
        <div>
            <WithAuthorizedComponent component={Statistics} permissions={RolePermissions.statisticsPagePermissions} />
        </div> :
        <Redirect to={"/login"} />
}

export default Home;