import { gql } from '@apollo/client';

export const TodayStatisticsQuery = gql
    `query StatisticsQuery{
        data: todayStatistics{
            value
            successRatio
        }
    }`;

export const ThisWeekStatisticsQuery = gql
    `query StatisticsQuery{
        data: thisWeekStatistics{
            value
            successRatio
        }
    }`;

export const ThisMonthStatisticsQuery = gql
    `query StatisticsQuery{
        data: thisMonthStatistics{
            value
            successRatio
        }
    }`;