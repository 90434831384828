import { FormControlLabel, Switch } from '@mui/material';
import React, { Component } from 'react';
import ApiClient from '../Api/ApiClient';
import AuthService from '../Auth/AuthService';
import ColorPallete from '../Common/ColorPallete';
import ConfirmationModal from '../Common/ConfirmationModal';
import Icons from '../Common/Icons';
import GenerateLiveLinkModal from './GenerateLiveLinkModal';

const { getAccessToken } = AuthService();
const { generateLiveLink, updateLiveLink } = ApiClient();

class GeneratedLiveLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showGenerateLiveLinkModal: false,
            showConfirmationModal: false,
            linkToBeUpdated: null,
        }
    }

    generateLink = async ({ productIds }) => {
        const accessToken = getAccessToken();
        const requestDto = {
            product_ids: productIds,
        };
        await generateLiveLink(accessToken, requestDto, this.onGenerateLinkSuccess, this.ApiFailureCallback);
    }

    onGenerateLinkSuccess = (responseDto) => {
        this.hideGenerateLiveLinkModal();
        if (responseDto && responseDto.succeeded === true) {
            this.props.onGenerateLink({
                payment_link: responseDto.response.payment_link,
                is_active: responseDto.response.is_active,
                products: responseDto.response.products
            });
            alert('Link generated successfully');
        } else {
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
    }

    UpdateLink = async () => {
        const accessToken = getAccessToken();
        const requestDto = {
            is_active: !this.state.linkToBeUpdated.is_active,
        };
        await updateLiveLink(accessToken, this.state.linkToBeUpdated.order_id, requestDto, this.onUpdateLinkSuccess, this.ApiFailureCallback);
    }

    onUpdateLinkSuccess = (responseDto) => {
        this.hideConfirmationModal();
        if (responseDto && responseDto.succeeded === true) {
            let updatedLiveLink = this.state.linkToBeUpdated;
            updatedLiveLink.is_active = !updatedLiveLink.is_active;
            this.props.onLiveLinkUpdated(updatedLiveLink);
            alert('Link Updated successfully');
        } else {
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
    }

    ApiFailureCallback = (error) => {
        console.log(error);
        alert('Unexpected error');
    }

    renderNoLinks = () => {
        return <div className='text-center'>
            <h5 style={{ color: ColorPallete.grey }}>No live links</h5>
        </div>
    }

    getProductNames = (products) => {
        return products.map(product => `(${product.name} ${product.price} EGP)`).join(', ');
    }

    renderLinkBox(link) {
        return (<div className='pt-3 pb-3 pr-3 code-box'>
            <div className='col-12'>
                <div className="copy-icon-box" onClick={() => { this.copy(link) }}>
                    <Icons.CopyIcon />
                </div>
            </div>
            <pre className='mt-4 pt-4 pl-3 pb-2 code-box'>
                <code className='mt-1'>
                    {link}
                </code>
            </pre>
        </div>);
    }

    copy(text) {
        navigator.clipboard.writeText(text);
    }

    onActivationChange = (link) => {
        this.setState({ showConfirmationModal: true, linkToBeUpdated: link });
    }

    renderLinkCards = (linkIndex) => {
        return (
            <div key={linkIndex} className='product-card p-4 mb-4'>
                <div className='form-row'>
                    <div className='ml-auto'>
                        <FormControlLabel className='mt-4' control={
                            <Switch checked={this.props.links[linkIndex].is_active}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                            onChange={() => this.onActivationChange(this.props.links[linkIndex])}
                            labelPlacement="start"
                        />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-lg-6'>
                        <label className={"col-form-label"}>Link</label>
                        {this.renderLinkBox(this.props.links[linkIndex].payment_link)}
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='form-group col-lg-5'>
                        <label className={"col-form-label"}>Products</label>
                        <textarea className={"form-control"} value={this.getProductNames(this.props.links[linkIndex].products)} disabled />
                    </div>

                </div>
            </div>);
    }

    showGenerateLiveLinkModal = () => {
        this.setState({ showGenerateLiveLinkModal: true });
    }

    hideGenerateLiveLinkModal = () => {
        this.setState({ showGenerateLiveLinkModal: false })
    }

    hideConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    }

    render() {
        return (
            <div className='mt-2 pt-4 ml-4'>
                <div className="row pt-2">
                    <div className="col-12 row">
                        <div className='col'>
                            <h4 className='title-color'>Live Links</h4>
                        </div>
                        <div className='ml-auto'>
                            <button className={"btn btn-block btn-primary"} onClick={() => this.showGenerateLiveLinkModal()}>Generate Link</button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className='row mt-4'>
                    <div className='col'>
                        {
                            this.props.links.length > 0 ? this.props.links.map((_, index) => this.renderLinkCards(index)) : this.renderNoLinks()
                        }
                    </div>
                </div>
                <GenerateLiveLinkModal show={this.state.showGenerateLiveLinkModal} products={this.props.products} onGenerateLink={this.generateLink} onEscapeKeyPress={this.hideGenerateLiveLinkModal} />
                <ConfirmationModal
                    message={`Do you want to ${this.state.linkToBeUpdated?.is_active ? 'deactivate' : 'activate'} this link?`}
                    show={this.state.showConfirmationModal}
                    onEscapeKeyPress={this.hideConfirmationModal}
                    onConfirm={() => this.UpdateLink()} />
            </div>
        );
    }
}

export default GeneratedLiveLinks;