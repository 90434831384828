import React, { Component } from 'react';
import LoadingCircle from '../Common/LoadingCircle';

class StatisticsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
        };
    }

    componentDidMount() {
        this.props.fetch(this.props.query, (result) => {
            this.setState({
                data: result.data.data,
                loading: result.loading,
            });
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.loading ? <LoadingCircle height={40} width={40} /> :
                        <div className={"text-center"}>
                            <div className='col-12'>
                                <h5><strong>{this.state.data.value.toFixed(2)} EGP
                                </strong></h5>
                            </div>
                            <div className='col-12'>
                                <p>{this.props.title}</p>
                            </div>
                            <div>
                                <span>Success Rate:</span><span className='subtitle-color'> {Number((parseFloat(this.state.data.successRatio) * 100).toFixed(2))}% {this.props.subTitle}</span>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default StatisticsCard;