import DateInputField from "../Common/DateInputField";
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import { PaymentMethodType } from "../../Constants";
import Icons from "../Common/Icons";
import Select from 'react-select'

const transactionStatus = [
    { label: 'InProgress', value: 1 },
    { label: 'Successful', value: 2 },
    { label: 'Failed', value: 3 },
    { label: 'Refunded', value: 4 },
    { label: 'PendingAuthentication', value: 5 },
    { label: 'Authenticated', value: 6 },
];

class TransactionsFiltersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer_id: null,
            merchant_transaction_reference: null,
            system_transaction_reference: null,
            payment_method_type: null,
            transaction_status_list: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        }

        this.handleCustomerIDChange = this.handleCustomerIDChange.bind(this);
        this.handleMerchantTransactionReferenceChange = this.handleMerchantTransactionReferenceChange.bind(this);
        this.handleSystemTransactionReferenceChange = this.handleSystemTransactionReferenceChange.bind(this);
        this.handleTransactionStatusChange = this.handleTransactionStatusChange.bind(this);
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.handleStartRangeDateChange = this.handleStartRangeDateChange.bind(this);
        this.handleEndRangeDateChange = this.handleEndRangeDateChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleCustomerIDChange(event) {
        this.setState({
            customer_id: event.target.value
        });
    }
    handleMerchantTransactionReferenceChange(event) {
        this.setState({
            merchant_transaction_reference: event.target.value,
        });
    }
    handleSystemTransactionReferenceChange(event) {
        this.setState({
            system_transaction_reference: event.target.value,
        });
    }
    handleTransactionStatusChange = (event) => {
        const selectedOptions = Array.from(event).map(option => option.value);
        this.setState({transaction_status_list: selectedOptions});
    }
    handlePaymentMethodChange(event) {
        this.setState({
            payment_method_type: parseInt(event.target.value),
        });
    }

    handleStartRangeDateChange(date) {
        this.setState({
            transactions_time_range_start: date,
        });
    }

    handleEndRangeDateChange(date) {
        this.setState({
            transactions_time_range_end: date,
        });
    }

    handleSearchButtonClick() {
        this.props.handleSearchButtonClick(this.state);
        this.resetState();

    }

    close = () => {
        this.resetState();
        this.props.onEscapeKeyPress();
    }

    resetState = () => {
        this.setState({
            customer_id: null,
            merchant_transaction_reference: null,
            system_transaction_reference: null,
            payment_method_type: null,
            transaction_status_list: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        });
    }

    render() {
        return (
            <Modal show={this.props.show} size="xl">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4">
                        <div className="row">
                            <div className="col-lg-10">
                                <h4><strong>Search</strong></h4>
                            </div>
                            <div className="col-lg-2">
                                <button className={"btn btn-block btn-primary"} onClick={this.handleSearchButtonClick}>Search</button>
                            </div>
                        </div>
                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Customer ID</label>
                                <input type={"text"} className={"form-control"} placeholder="#ID" onChange={this.handleCustomerIDChange} />
                            </div>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Merchant Ref.</label>
                                <input type={"text"} className={"form-control"} placeholder="#Merchant Ref" onChange={this.handleMerchantTransactionReferenceChange} />
                            </div>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>System Ref.</label>
                                <input type={"text"} className={"form-control"} placeholder="#System Ref" onChange={this.handleSystemTransactionReferenceChange} />
                            </div>
                        </div>

                        <div className={"form-row"}>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Order Status</label>
                                <Select
                                    options={transactionStatus}
                                    touchUi={false}
                                    isMulti
                                    onChange={this.handleTransactionStatusChange}
                                />
                            </div>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Payment Method</label>
                                <select className={"form-control"} onChange={this.handlePaymentMethodChange}>
                                    {
                                        Object.keys(PaymentMethodType).map((key, index) => {
                                            return <option key={index} value={PaymentMethodType[key]}>{key}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <label className={"col-form-label"}>Time</label>
                        </div>
                        <div className={"form-row"}>
                            <div className={"form-group col-md-6 date-picker"}>
                                <DateInputField setDate={this.handleStartRangeDateChange} label="From:" max={this.state.transactions_time_range_end} />
                            </div>
                            <div className={"form-group col-md-6 date-picker"}>
                                <DateInputField setDate={this.handleEndRangeDateChange} label="To:" min={this.state.transactions_time_range_start} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>);
    }
}

export default TransactionsFiltersModal;