import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "../Pages/Home";
import WithAuthenticationRoute from "./Routing/WithAuthenticationRoute";
import Transactions from "../Pages/Transactions";
import Batches from "../Pages/Batches";
import Settings from "../Pages/Settings";
import RolePermissions from "./PermissionProvider/RolePermissions";
import Login from "../Pages/Login";
import Signup from "../Pages/Signup";
import Navbar from "./Navbar/Navbar";
import IntegrationGuide from "../Pages/IntegrationGuide";
import LiveLinks from "../Pages/LiveLinks";

function Main() {
    return (
        <div id="app">
            <Router history={createBrowserHistory()}>
                <Route path={'/login'} component={Login} />
                <Route path={'/signup'} component={Signup} />
                
                <div className="row">
                    <div className="col-md-3">
                        <Navbar />
                    </div>
                    <div className="col-md-8">
                        <Route exact path={'/'} component={Home} />
                        <WithAuthenticationRoute path={'/transactions'} component={Transactions} permissions={RolePermissions.transactionsPagePermissions} />
                        <WithAuthenticationRoute path={'/transfers'} component={Batches} permissions={RolePermissions.transfersPagePermissions} />
                        <WithAuthenticationRoute path={'/settings'} component={Settings} permissions={RolePermissions.settingsPagePermissions} />
                        <WithAuthenticationRoute path={'/integration/guide'} component={IntegrationGuide} />
                        <WithAuthenticationRoute path={'/live/links'} component={LiveLinks} />
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </Router >
        </div >
    );
}

export default Main;