import { v4 as uuidv4 } from 'uuid';
import cryptoJs from "crypto-js";

async function Send(actualSending, success, failure) {
    try {
        let response = await actualSending();
        if (success) {
            success(response);
        }
    } catch (error) { // catch only network errors
        if (failure) {
            failure(error);
        }
        else {
            console.log(error);
        }
    }
}

function constructRequest(body) {
    return {
        request_id: uuidv4(),
        client_timestamp_utc: new Date().toUTCString(),
        request_body: body
    }
}

function calculateHash(request, key) {
    var hash = cryptoJs.HmacSHA256(request, key);
    return cryptoJs.enc.Hex.stringify(hash);
}

const ApiClient = () => {

    const domain = process.env.REACT_APP_API_ENDPOINT;
    const initiateRegistrationEndpoint = `${domain}/api/onboarding/initiate_registration`;
    const registrationEndpoint = `${domain}/api/onboarding/register`;
    const refundApiUrl = `${domain}/api/payment/reversal`;
    const getCredentialsApiUrl = `${domain}/api/Onboarding/credentials`;
    const onboardingStateEndpoint = `${domain}/api/onboarding/state`;
    const getConfigurationsEndpoint = `${domain}/api/onboarding/configurations`;
    const updateConfigurationsEndpoint = `${domain}/api/onboarding/configurations`;
    const identityVerificationEndpoint = `${domain}/api/onboarding/verification/submit`;
    const liveLinksProfileEndpoint = `${domain}/api/PaymentLinkGeneration/profile`;
    const getLiveLinkProductsEndpoint = `${domain}/api/PaymentLinkGeneration/products`;
    const addLiveLinkProductEndpoint = `${domain}/api/PaymentLinkGeneration/product`;
    const getLiveLinksEndpoint = `${domain}/api/PaymentLinkGeneration`;
    const generateLiveLinkEndpoint = `${domain}/api/PaymentLinkGeneration`;

    const initiateRegistration = async (initiateRegistrationRequestDto, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const jsonRequest = JSON.stringify(constructRequest(initiateRegistrationRequestDto));
                const initiateRegistrationResponseDto = await fetch(initiateRegistrationEndpoint, {
                        method: 'POST',
                        body: jsonRequest,
                        headers: {
                            'Content-type': 'application/json',
                        },
                    }
                );
                return await initiateRegistrationResponseDto.json();
            }, successCallback, failureCallback);
    }
    
    const register = async (registerRequestDto, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const jsonRequest = JSON.stringify(constructRequest(registerRequestDto));
                const registerResponseDto = await fetch(registrationEndpoint, {
                    method: 'POST',
                    body: jsonRequest,
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
                );
                return await registerResponseDto.json();
            }, successCallback, failureCallback);
    };

    const refund = async (authToken, refundRequestDto, requestHashKey, success, failure) => {
        return await Send(async () => {
            const jsonRequest = JSON.stringify(constructRequest(refundRequestDto), null, 4);
            const requestHash = calculateHash(jsonRequest, requestHashKey);
            const refundResponseDto = await fetch(refundApiUrl, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'content-type': 'application/json',
                    'request-hash': requestHash,
                },
                method: 'POST',
                body: jsonRequest,
            });

            return await refundResponseDto.json();
        }, success, failure);
    };

    const getCredentials = async (authToken, success, failure) => {
        return await Send(async () => {
            const gerCredentials = await fetch(getCredentialsApiUrl, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'content-type': 'application/json',
                },
                method: 'GET',
            });

            return await gerCredentials.json();
        }, success, failure);
    };
    
    const getOnboardingState = async (accessToken, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const onboardingStateResponseDto = await fetch(onboardingStateEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await onboardingStateResponseDto.json();
            }, successCallback, failureCallback
        );
    }

    const getConfigurations = async (accessToken, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const configurationResponseDto = await fetch(getConfigurationsEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });

                return await configurationResponseDto.json();
            }, successCallback, failureCallback
        );
    }

    const updateConfiguration = async (accessToken, requestDto, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const jsonRequest = JSON.stringify(constructRequest(requestDto));
                const updateConfigurationResponseDto = await fetch(updateConfigurationsEndpoint, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                    body: jsonRequest,
                });

                return await updateConfigurationResponseDto.json();
            }, successCallback, failureCallback
        );
    }
    
    const submitIdentityVerificationRequest = async (accessToken, requestDto, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const jsonRequest = JSON.stringify(constructRequest(requestDto));
                const identityVerificationSubmissionResponseDto = await fetch(identityVerificationEndpoint, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                    body: jsonRequest,
                });
                
                return await identityVerificationSubmissionResponseDto.json();
            }, successCallback, failureCallback
        );
    }

    const getLiveLinksProfile = async (accessToken, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const response = await fetch(liveLinksProfileEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const updateLiveLinksProfile = async (accessToken, requestDto, successCallback, failureCallback) => {
        const jsonRequest = JSON.stringify(constructRequest(requestDto));
        return await Send(
            async () => {
                const response = await fetch(liveLinksProfileEndpoint, {
                    method: 'PUT',
                    body: jsonRequest,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const getLiveLinkProducts = async (accessToken, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const response = await fetch(getLiveLinkProductsEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const addLiveLinkProduct = async (accessToken, requestDto, successCallback, failureCallback) => {
        const jsonRequest = JSON.stringify(constructRequest(requestDto));
        return await Send(
            async () => {
                const response = await fetch(addLiveLinkProductEndpoint, {
                    method: 'POST',
                    body: jsonRequest,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const getLiveLinks = async (accessToken, successCallback, failureCallback) => {
        return await Send(
            async () => {
                const response = await fetch(getLiveLinksEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const generateLiveLink = async (accessToken, requestDto, successCallback, failureCallback) => {
        const jsonRequest = JSON.stringify(constructRequest(requestDto));
        return await Send(
            async () => {
                const response = await fetch(generateLiveLinkEndpoint, {
                    method: 'POST',
                    body: jsonRequest,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }

    const updateLiveLink = async (accessToken, linkId, requestDto, successCallback, failureCallback) => {
        const jsonRequest = JSON.stringify(constructRequest(requestDto));
        return await Send(
            async () => {
                const response = await fetch(`${generateLiveLinkEndpoint}/${linkId}`, {
                    method: 'PUT',
                    body: jsonRequest,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                });
                
                return await response.json();
            }, successCallback, failureCallback
        );
    }
    
    return {
        initiateRegistration,
        register,
        refund,
        getCredentials,
        getOnboardingState,
        getConfigurations,
        updateConfiguration,
        submitIdentityVerificationRequest,
        getLiveLinksProfile,
        updateLiveLinksProfile,
        getLiveLinkProducts,
        addLiveLinkProduct,
        getLiveLinks,
        generateLiveLink,
        updateLiveLink,
    };
};

export default ApiClient;