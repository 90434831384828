import React, {Component} from "react";
import {Container} from "@mui/material";
import ThemeUtils from "./ThemeUtils";

class Logo extends Component{
    render() {
        return <Container sx={{margin: '20%'}}>
            <h1 className={"primary-color"}><strong>{ThemeUtils.getTitleOrLogo()}</strong></h1>
            <div><span className="title-color">Acceptance</span> <span className="subtitle-color">Dashboard</span></div>
        </Container>
    }
}

export default Logo;