import { ApolloClient, InMemoryCache } from "@apollo/client";

function GraphQLClient(accessToken){
    const domain = process.env.REACT_APP_API_ENDPOINT;

    return {
        client: new ApolloClient({
            uri: domain + '/api/portal',
            cache: new InMemoryCache(),
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        })
    };
}

export default GraphQLClient;