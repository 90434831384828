import React, {Component} from "react";
import { Button } from "react-bootstrap";
import {
    Alert,
    Box,
    Container,
    FormControl,
    FormGroup,
    InputAdornment,
    Link,
    TextField
} from "@mui/material";
import AuthService from "./AuthService";
import LoadingCircle from "../Common/LoadingCircle";
import ApiClient from "../Api/ApiClient";
import OtpModal from "./OtpModal";
import {Visibility, VisibilityOff} from "@mui/icons-material";

class SignupForm extends Component{
    constructor(props) {
        super(props);
        
        this.state = {
            email: '',
            password: '',
            name: '',
            phone_number: '',
            error: false,
            error_message: '',
            otpModalError: false,
            otpModalErrorMessage: '',
            loading: false,
            showOtpModal: false,
            toggle_password: false,
            showPasswordHint: false,
            showUserNameHint: false,
            satisfiedPasswordLength: false,
            satisfiedUppercase: false,
            satisfiedLowercase: false,
            satisfiedDigit: false,
            satisfiedSpecialCharacter: false,
            satisfiedUserNameCondition: false,
        };
        
        this.allConditionsSatisfied = this.allConditionsSatisfied.bind(this);
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.hideOtpModal = this.hideOtpModal.bind(this);
        this.togglePasswordHide = this.togglePasswordHide.bind(this);
        
        this.initiateRegistration = this.initiateRegistration.bind(this);
        this.register = this.register.bind(this);
        
        this.initiateRegistrationSuccessCallback = this.initiateRegistrationSuccessCallback.bind(this);
        this.registrationSuccessCallback = this.registrationSuccessCallback.bind(this);
        this.signupFailureCallback = this.signupFailureCallback.bind(this);
    }
    
    allConditionsSatisfied(){
        return(
            this.state.satisfiedPasswordLength &&
            this.state.satisfiedUppercase &&
            this.state.satisfiedLowercase &&
            this.state.satisfiedDigit &&
            this.state.satisfiedSpecialCharacter &&
            this.state.satisfiedUserNameCondition
        );
    }
    
    handleUserNameChange(e){
        const value = e.target.value;
        
        this.setState({ name: value });
        
        const userName = new RegExp('^[A-Za-z0-9]+$');
        if(userName.test(value)){
            this.setState({ satisfiedUserNameCondition: true});
        }else{
            this.setState({ satisfiedUserNameCondition: false});
        }
    }
    
    handlePasswordChange(e){
        const lower = new RegExp('(?=.*[a-z])');
        const upper = new RegExp('(?=.*[A-Z])');
        const number = new RegExp('(?=.*[0-9])');
        const special = new RegExp('(?=.*[!@#$%^&*])');
        const length = new RegExp('(?=.{8,})');
        const value = e.target.value;

        this.setState({ password: value });

        if(lower.test(value)){
            this.setState({satisfiedLowercase: true});
        }
        else{
            this.setState({satisfiedLowercase: false});
        }
        
        if(upper.test(value)){
            this.setState({satisfiedUppercase: true});
        }
        else{
            this.setState({satisfiedUppercase: false});
        }
        
        if(number.test(value)){
            this.setState({satisfiedDigit: true});
        }
        else{
            this.setState({satisfiedDigit: false});
        }
        
        if(special.test(value)){
            this.setState({satisfiedSpecialCharacter: true});
        }
        else{
            this.setState({satisfiedSpecialCharacter: false});
        }
        
        if(length.test(value)){
            this.setState({satisfiedPasswordLength: true});
        }
        else{
            this.setState({satisfiedPasswordLength: false});
        }
    }
    
    hideOtpModal(){
        this.setState({ showOtpModal: false });
    }

    togglePasswordHide(){
        this.setState({ toggle_password: !this.state.toggle_password});
    }
    
    registrationSuccessCallback(response){
        const { login } = AuthService();
        
        if(response.succeeded === true){
            this.setState( { error: false, otpModalError: false, showOtpModal: false });
            login(this.state.email, this.state.password);
        }else {
            this.setState({ otpModalError: true, otpModalErrorMessage: response.error_message });
        }
        this.setState( { loading: false });
    }
    
    signupFailureCallback(error){
        this.setState({
            error: true,
            error_message: error.message,
        });
    }
    
    initiateRegistrationSuccessCallback(response){
        if(response.succeeded === true){
            if(response.is_mobile_verification_required === true){
                this.setState( { error: false, showOtpModal: true });
            }else{
                this.setState({ loading: true });
                this.register('').then(() => {
                    this.setState({ loading: false });
                });
            }
        }else {
            this.setState({ error: true, error_message: response.error_message, showOtpModal: false });
        }
        this.setState( { loading: false });
    }
    
    async initiateRegistration(e){
        e.preventDefault();
        
        if(this.allConditionsSatisfied() === false){
            this.setState({ error: true, error_message: "Please satisfy all constraints."});
            return;
        }
        
        this.setState( { loading: true });

        const { initiateRegistration } = ApiClient();
        const initiateRegistrationRequestDto = {
            email: this.state.email,
            phone_number: this.state.phone_number,
        };
        
        await initiateRegistration(initiateRegistrationRequestDto, this.initiateRegistrationSuccessCallback, this.signupFailureCallback);

        this.setState( { loading: false });
    }
    
    async register(otp){
        const { register } = ApiClient();
        const registerRequestDto = {
            email: this.state.email,
            password: this.state.password,
            name: this.state.name,
            phone_number: this.state.phone_number,
            otp: otp,
        };
        
        await register(registerRequestDto, this.registrationSuccessCallback, this.signupFailureCallback);
    }
    
    render(){
        const { isAuthenticated } = AuthService();
        
        return !isAuthenticated() &&
            <Container sx={{margin: '10%'}}>
                <h4>Register</h4>
                <FormGroup>
                    <FormControl>
                        <TextField
                            id="email"
                            label="Email Address"
                            variant="outlined"
                            margin={"normal"}
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                        />
                    </FormControl>
    
                    <FormControl>
                        <TextField
                            id="password"
                            label="Password"
                            variant="outlined"
                            margin={"normal"}
                            type = { this.state.toggle_password ? "text" : "password" }
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                            onFocus={() => this.setState({ showPasswordHint: true})}
                            onBlur={() => this.setState( { showPasswordHint: false})}
                            helperText={
                                this.state.showPasswordHint ?
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <span className={this.state.satisfiedPasswordLength ? "satisfied_condition" : "non_satisfied_condition"}>* At least 8 characters length</span>
                                    <span className={this.state.satisfiedLowercase ? "satisfied_condition" : "non_satisfied_condition"}>* At least 1 lowercase letter</span>
                                    <span className={this.state.satisfiedUppercase ? "satisfied_condition" : "non_satisfied_condition"}>* At least 1 uppercase letter</span>
                                    <span className={this.state.satisfiedSpecialCharacter ? "satisfied_condition" : "non_satisfied_condition"}>* At least 1 special character</span>
                                    <span className={this.state.satisfiedDigit ? "satisfied_condition" : "non_satisfied_condition"}>* At least 1 digit</span>
                                </Box>
                                    :null
                            }
                            InputProps = {
                                {
                                    endAdornment: (
                                        <InputAdornment position="end"> {
                                            this.state.toggle_password ? 
                                            <Visibility onClick = {this.togglePasswordHide}/>
                                            :
                                            <VisibilityOff onClick = {this.togglePasswordHide}/>
                                        }
                                        </InputAdornment>
                                    ),
                                }
                            }
                        />
                    </FormControl>
    
                    <FormControl>
                        <TextField
                            id="name"
                            label="Name"
                            variant="outlined"
                            margin={"normal"}
                            value={this.state.name}
                            onChange={this.handleUserNameChange}
                            onFocus={() => this.setState({ showUserNameHint: true})}
                            onBlur={() => this.setState( { showUserNameHint: false})}
                            helperText={
                                this.state.showUserNameHint ?
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <span className={this.state.satisfiedUserNameCondition ? "satisfied_condition" : "non_satisfied_condition"}>* Only lowercase, uppercase and digits are allowed</span>
                                </Box>
                                :null
                            }
                        />
                    </FormControl>
    
                    <FormControl>
                        <TextField
                            id="phoneNumber"
                            label="Phone Number"
                            variant="outlined"
                            margin={"normal"}
                            value={this.state.phone_number}
                            onChange={(e) => this.setState({ phone_number: e.target.value })}
                        />
                    </FormControl>
    
                    <FormControl margin={"normal"}>
                        {
                            this.state.loading ? <LoadingCircle height={40} width={40} /> :
                                <Button onClick={this.initiateRegistration} sx={{width: '60%', align:'center'}}>Register</Button>
                        }
                    </FormControl>
                    <FormControl margin={"normal"}>
                        <Link href={"/login"} textAlign={"center"}>already have an account?</Link>
                    </FormControl>
                </FormGroup>
                {
                    this.state.error &&
                    <Alert severity="error">{this.state.error_message}</Alert>
                }
                
                <OtpModal show={this.state.showOtpModal} error={this.state.otpModalError} error_message={this.state.otpModalErrorMessage} register={this.register} onEscapeKeyPress={this.hideOtpModal}/>
            </Container>
    }
}

export default SignupForm;