import React from "react";
import {Alert, Box, FormControlLabel, Switch, Typography} from "@mui/material";
import LoadingCircle from "../Common/LoadingCircle";
import ColorPallete from "../Common/ColorPallete";

class MerchantConfigurations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            loading: false,
            error: false,
            error_message: '',
            notification_endpoint: this.props.configurations ? this.props.configurations.notification_endpoint : "",
            is_bank_card_enabled: this.props.configurations ? this.props.configurations.is_bank_card_enabled : false,
            is_wallet_enabled: this.props.configurations ? this.props.configurations.is_wallet_enabled : false,
            is_meeza_card_enabled: this.props.configurations ? this.props.configurations.is_meeza_card_enabled : false,
        };
        
        this.handleOpenSwitchClick = this.handleOpenSwitchClick.bind(this);
        this.updateConfigurationsSuccessCallback = this.updateConfigurationsSuccessCallback.bind(this);
        this.updateConfigurations = this.updateConfigurations.bind(this);
        this.getMerchantConfig = this.getMerchantConfig.bind(this);
    }
    
    getMerchantConfig(){
        return {
            notification_endpoint: this.state.notification_endpoint,
            is_bank_card_enabled: this.state.is_bank_card_enabled,
            is_wallet_enabled: this.state.is_wallet_enabled,
            is_meeza_card_enabled: this.state.is_meeza_card_enabled,
        };
    }
    handleOpenSwitchClick(){
        if(this.props.canEdit === true){
            this.setState({
                opened: !this.state.opened,
                error: false,
            });
        }else{
            this.setState({
                error: true,
                error_message: "You are not allowed to edit, Create credentials first",
            });
        }
    }
    
    updateConfigurationsSuccessCallback(response){
        if(response && response.succeeded === true){
            this.props.setMerchantConfigurations(
                this.getMerchantConfig()
            );
            this.setState({ error: false });
        }else{
            this.setState({
                error: true,
                error_message: `Error while updating merchant configurations, ${response.error_message}`
            });
        }
    }
    
    async updateConfigurations(){
        this.setState({ loading: true });
        
        await this.props.updateMerchantConfigurations(
            this.getMerchantConfig(),
            this.updateConfigurationsSuccessCallback
        );
        
        this.setState({ loading: false });
    }
    
    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: this.state.opened ? 'background.paper' : ColorPallete.lightGrey,
                    borderRadius: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                    }}
                >
                    <Typography variant="h5"><strong>Configurations</strong></Typography>
                    <FormControlLabel control={
                        <Switch checked={this.state.opened}
                                onChange={this.handleOpenSwitchClick}
                                inputProps={{ 'aria-label': 'controlled' }}
                        />
                    } label="Edit"/>
                </Box>

                <div className={"form-group col-12"}>
                    <label className={"col-form-label"}>Notification Url</label>
                    <input type={"text"} 
                           className={"form-control"}
                           value={this.state.notification_endpoint} 
                           disabled={!this.state.opened}
                           onChange={(e) => this.setState({ notification_endpoint: e.target.value })}/>
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        p: 1,
                        m: 1,
                    }}
                >
                    <FormControlLabel control={
                        <Switch checked={this.state.is_bank_card_enabled}
                                onChange={() => this.setState({is_bank_card_enabled: !this.state.is_bank_card_enabled})}
                                inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                                      disabled={!this.state.opened}
                                      label="Bank Card"
                                      labelPlacement="start"
                    />
                    <FormControlLabel control={
                        <Switch checked={this.state.is_wallet_enabled}
                                onChange={() => this.setState({is_wallet_enabled: !this.state.is_wallet_enabled})}
                                inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                                      disabled={!this.state.opened}
                                      label="Wallet"
                                      labelPlacement="start"
                    />
                    <FormControlLabel control={
                        <Switch checked={this.state.is_meeza_card_enabled}
                                onChange={() => this.setState({is_meeza_card_enabled: !this.state.is_meeza_card_enabled})}
                                inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                                      disabled={!this.state.opened}
                                      label="Meeza Card"
                                      labelPlacement="start"
                    />
                </Box>

                <div className="mt-3 text-center">
                    {
                        this.state.opened ?
                            this.state.loading ? <LoadingCircle width={40} height={40} /> :
                                <button
                                    onClick={this.updateConfigurations}
                                    className="btn btn-primary"> Update
                                </button>
                            : <></>
                    }
                </div>
                
                {
                    this.state.error &&
                    <Alert severity="error">{this.state.error_message}</Alert>
                }
            </Box>
            
        );
    }
}

export default MerchantConfigurations;