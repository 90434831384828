import React, {Component} from 'react';
import {Box, Button, FormControl, FormGroup, TextField} from "@mui/material";

class MerchantDetails extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            company_name: this.props.initialValues.company_name,
            company_address: this.props.initialValues.company_address,
        };
        
        this.isError = this.isError.bind(this);
        this.submit = this.submit.bind(this);
    }
    
    isError(){
        return !this.state.company_name || !this.state.company_address;
    }
    
    submit(){
        this.props.submitAction(this.state);
        this.props.handleNext();
    }
    
    render() {
        return (
            <FormGroup>
                <FormControl>
                    <TextField
                        id="company_name"
                        label="Company Name"
                        variant="outlined"
                        margin={"normal"}
                        value={this.state.company_name}
                        onChange={(e) => {this.setState({company_name: e.target.value})}}
                        required={true}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        id="company_address"
                        label="Company Address"
                        variant="outlined"
                        margin={"normal"}
                        value={this.state.company_address}
                        onChange={(e) => {this.setState({company_address: e.target.value})}}
                        required={true}
                    />
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                        disabled={this.isError()}
                        color="primary"
                        onClick={!this.isError() ? this.submit : () => null}
                    >
                        Next
                    </Button>
                </Box>
            </FormGroup>
        );
    }
}

export default MerchantDetails;