import { gql } from '@apollo/client';

const BatchesQuery = gql
    `query BatchesQuery(
            $offset: Int,
            $limit: Int,
            $batch_id: Int,
            $batch_status: Int,
            $batches_time_range_start: DateTime,
            $batches_time_range_end: DateTime
            ){
            batchesPage(
                offset: $offset,
                limit: $limit, 
                batch_id: $batch_id,
                batch_status: $batch_status,
                batches_time_range_start: $batches_time_range_start,
                batches_time_range_end: $batches_time_range_end)
            {
                totalNumberOfBatches
                batches{
                  batchId
                  batchSettlementDate
                  batchStartDateTimeUtc
                  batchEndDateTimeUtc
                  batchStatus
                  dueAmount
                  totalNumberOfTransactions
                  totalPaidAmount
                  totalCommission
                }
            }
            }`;

export default BatchesQuery;