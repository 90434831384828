import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import Icons from "./Icons";


class ConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }


    close = () => {
        this.props.onEscapeKeyPress();
    }

    render() {
        return (
            <Modal show={this.props.show} size="md">
                <Modal.Header>
                    <div className='mt-1'>
                        <h4><strong>{this.props.title ?? 'Confirmation'}</strong></h4>
                    </div>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4">
                        <div className="row">
                            <div className="col-12">
                                <p>{this.props.message}</p>
                            </div>
                        </div>
                        <div className='row mt-4'>
                        <div className='col-1'></div>
                        <div className='col-5'>
                            <button className='btn btn-block btn-success' onClick={this.props.onConfirm}>Yes</button>
                        </div>
                        <div className='col-5'>
                        <button className='btn btn-block btn-danger' onClick={this.close}>No</button>
                        </div>
                        <div className='col-1'></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>);
    }
}

export default ConfirmationModal;