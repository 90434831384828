import {format} from "date-fns";

const metrics = {
    1: "Transaction Count",
    2: "Processed Value",
    3: "Success Rate"
};

class Utils{
    static dateFormatter(date){
        return format(new Date(date), "dd-MMM-yyyy");
    }
    
    static getMetricName(metric){
        return metrics[metric];
    }
}

export default Utils;