import React from "react";
import Icons from "../Common/Icons";

export const SidebarItems = {
  Home: {
    type: 'link',
    title: "Home",
    path: "/",
    icon: <Icons.HomeIcon /> ,
  },
  Transactions: {
    type: 'link',
    title: "Transactions",
    path: "/transactions",
    icon: <Icons.TransactionsIcon />,
  },
  Transfers: {
    type: 'link',
    title: "Transfers",
    path: "/transfers",
    icon: <Icons.TransferIcon />,
  },
  Settings: {
    type: 'link',
    title: "Settings",
    path: "/settings",
    icon: <Icons.SettingsIcon />,
  },
  Integration: {
    type: 'link',
    title: "Integration Guide",
    path: "/integration/guide",
    icon: <Icons.HelpIcon />,
  },
  LiveLinks: {
    type: 'link',
    title: "Live Links",
    path: "/live/links",
    icon: <Icons.LiveLinkIcon />,
  },
  Hr: {
    type: 'hr'
  },
};
