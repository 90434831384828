import { gql } from '@apollo/client';

const TransactionsQuery = gql
    `query TransactionsQuery(
        $offset: Int,
        $limit: Int,
        $customer_id: String,
        $merchant_transaction_reference: String,
        $system_transaction_reference: String,
        $transaction_status_list: [Int],
        $payment_method_type: Int,
        $transactions_time_range_start: DateTime,
        $transactions_time_range_end: DateTime
        ){
        transactionsPage(
            offset: $offset,
            limit: $limit, 
            customer_id: $customer_id,
            merchant_transaction_reference: $merchant_transaction_reference,
            system_transaction_reference: $system_transaction_reference,
            transaction_status_list: $transaction_status_list,
            payment_method_type: $payment_method_type,
            transactions_time_range_start: $transactions_time_range_start,
            transactions_time_range_end: $transactions_time_range_end)
        {
            totalNumberOfTransactions
            transactions{
              customerId
              merchantTransactionReference
              systemTransactionReference
              paidAmount
              paymentMethodType
              transactionStatus
              transactionTimeStamp
              isPaymentLinkGenerationTransaction
              gatewayMessage
              gatewayDecision
              gatewayResponseCode
              gatewayReasonCode
              metadata{
                key
                value
              }
        }
      }
  }`;

export default TransactionsQuery;