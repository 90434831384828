import React, { Component } from 'react';
import ApiClient from '../Api/ApiClient';
import AuthService from '../Auth/AuthService';
import ColorPallete from '../Common/ColorPallete';
import { fileToBase64 } from '../Common/Utils';
import AddProductModal from './AddProductModal';

const { getAccessToken } = AuthService();
const { addLiveLinkProduct } = ApiClient();

class LiveLinkProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddProductModal: false,
        }
    }

    addProduct = async ({ name, description, price, image }) => {
        const accessToken = getAccessToken();
        const imageBase64 = await fileToBase64(image);
        const addProductRequestDto = {
            name: name,
            description: description,
            price: +price,
            image: imageBase64.split(',')[1],
        };
        await addLiveLinkProduct(accessToken, addProductRequestDto, this.onAddProductSuccess, this.ApiFailureCallback);
    }

    onAddProductSuccess = (responseDto) => {
        this.hideAddProductModal();
        if (responseDto && responseDto.succeeded === true) {
            this.props.onAddProduct(responseDto.response);
            alert('Product added successfully');
        } else {
            console.log(responseDto.error_message);
            alert(responseDto.error_message);
        }
    }

    ApiFailureCallback = (error) => {
        console.log(error);
        alert('Unexpected error');
    }

    renderNoProducts = () => {
        return <div className='text-center'>
            <h5 style={{ color: ColorPallete.grey }}>No products</h5>
        </div>
    }

    renderProductCard = (productIndex) => {
        return (
            <div key={this.props.products[productIndex].id} className='product-card p-4 mb-4'>
                <div className='form-row'>
                    <div className='form-group col-lg-4'>
                        <label className={"col-form-label"}>Name</label>
                        <input type={"text"} maxLength={50} className={"form-control"} value={this.props.products[productIndex].name} disabled />
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='form-group col-lg-7'>
                        <label className={"col-form-label"}>Description</label>
                        <textarea className={"form-control"} maxLength={300} value={this.props.products[productIndex].description} disabled />
                    </div>
                </div>
                <div className='form-row mt-2'>
                    <div className='form-group col-lg-4'>
                        <label className={"col-form-label"}>Price</label>
                        <input type={"number"} className={"form-control"} value={this.props.products[productIndex].price}
                            onChange={(event) => this.onProductChange(productIndex, event, 'price')} disabled />
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='form-group col-lg-7'>
                        <label className={"col-form-label"}>Image</label>
                        <img className='form-control' style={{ maxHeight: 300, maxWidth: 300, width: 'auto', height: 'auto' }} src={this.props.products[productIndex].image_link} alt={"Product"} />
                    </div>
                </div>
            </div>);
    }

    showAddProductModal = () => {
        this.setState({ showAddProductModal: true });
    }

    hideAddProductModal = () => {
        this.setState({ showAddProductModal: false })
    }

    render() {
        return (
            <div className='mt-2 pt-4 ml-4'>
                <div className="row pt-2">
                    <div className="col-12 row">
                        <div className='col'>
                            <h4 className='title-color'>Products</h4>
                        </div>
                        <div className='ml-auto'>
                            <button className={"btn btn-block btn-primary"} onClick={() => this.showAddProductModal()}>Add Product</button>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className='row mt-4'>
                    <div className='col'>
                        {
                            this.props.products.length > 0 ? this.props.products.map((_, index) => this.renderProductCard(index)) : this.renderNoProducts()
                        }
                    </div>
                </div>
                <AddProductModal show={this.state.showAddProductModal} onAddProduct={this.addProduct} onEscapeKeyPress={this.hideAddProductModal} />
            </div>
        );
    }
}

export default LiveLinkProducts;