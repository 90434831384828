import React, { Component } from 'react';
import { PaymentMethodType } from '../../Constants';
import DateInputField from '../Common/DateInputField';

class FiltersSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payment_method_type: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        }
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
        this.handleStartRangeDateChange = this.handleStartRangeDateChange.bind(this);
        this.handleEndRangeDateChange = this.handleEndRangeDateChange.bind(this);
    }

    handlePaymentMethodChange(event) {
        this.setState({
            payment_method_type: parseInt(event.target.value),
        });
    }
    
    handleFilterButtonClick() {
        this.props.fetch(this.state);
    }

    handleStartRangeDateChange(date) {
        this.setState({
            transactions_time_range_start: date,
        });
    }

    handleEndRangeDateChange(date) {
        this.setState({
            transactions_time_range_end: date,
        });
    }

    render() {
        return (
            <div>
                <div className={"form-row p-2"}>
                    <div className={"form-group col-12"}>
                        <label className={"col-form-label"}>Payment Method</label>
                        <select className={"form-control"} onChange={this.handlePaymentMethodChange}>
                            {
                                Object.keys(PaymentMethodType).map((key, index) => {
                                    return <option key={index} value={PaymentMethodType[key]}>{key}</option>;
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className={"form-row p-2"}>
                    <div className={"form-group col-12"}>
                        <DateInputField setDate={this.handleStartRangeDateChange} label="From:" max={this.state.transactions_time_range_end} />
                    </div>
                </div>
                <div className="form-row pt-4 p-2">
                    <div className={"form-group col-12"}>
                        <DateInputField setDate={this.handleEndRangeDateChange} label="To:" min={this.state.transactions_time_range_start} />
                    </div>
                </div>

                <div className={"mt-4 text-center pl-4 pr-4"}>
                    <button className={"btn btn-block btn-primary"} onClick={this.handleFilterButtonClick}>Apply Filters</button>
                </div>
            </div>
        );
    }
}

export default FiltersSection;