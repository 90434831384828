import {NavLink} from "react-router-dom";
import React from "react";

export default function NavbarItem(props){
    return props.item?.type === 'hr' ?
    (
        <li key={props.index + 1} style={{ listStyle: 'none' }} className="m-4">
            <hr />
        </li>
    )
    : (
        <li key={props.index + 1} className="nav-text">
            <NavLink activeClassName="is-active" to={props.item.path} exact>
                <span>{props.item.icon}</span>
                <span className="link-title ml-3">{props.item.title}</span>
            </NavLink>
        </li>
    );
}