import React, { Component } from 'react';
import Utils from './Utils';
import FilterSection from "./FilterSection";
import '../../App.css';
import AreaChart from './AreaChart';
import dateFormat from 'dateformat';
import LoadingCircle from '../Common/LoadingCircle';

class Chart extends Component {
    constructor(props) {
        super(props);

        const currentDate = new Date();

        let defaultStartDate = new Date(currentDate);
        defaultStartDate.setDate(defaultStartDate.getDate() - 7);

        const defaultEndDate = new Date(Date.now());

        this.state = {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            chartData: [],
            loading: true,
        };

        this.filters = {
            payment_method_type: null,
            transactions_time_range_start: defaultStartDate,
            transactions_time_range_end: defaultEndDate
        }

        this.fetch = this.fetch.bind(this);
    }

    fetch(filters) {
        this.filters = filters;

        this.setState({ loading: true });
        this.props.fetch(this.props.metric, this.filters, (result) => {
            const data = result.data.chart.chartData.map((element) => {
                return {
                    dataPointDate: new Date(element.dataPointDate),
                    dataPointValue: element.dataPointValue,
                };
            });
            this.setState({
                loading: false,
                chartData: data,
                startDate: filters.transactions_time_range_start,
                endDate: filters.transactions_time_range_end,
            });
        });
    }

    componentDidMount() {
        this.fetch(this.filters);
    }

    render() {
        return (
            <div className='pt-4'>
                <div className='text-center'>
                    <h2>{Utils.getMetricName(this.props.metric)}</h2>
                </div>
                <div className={"form-row mt-4 col-12"}>
                    <div className={"form-group mt-4 col-md-8"}>
                        {
                            this.state.loading ? <div className='mt-20'><LoadingCircle height={50} width={50} /></div> :
                                <AreaChart
                                    series={{ name: Utils.getMetricName(this.props.metric), data: this.state.chartData.map(d => d.dataPointValue) }}
                                    xAxis={{ type: 'datetime', categories: this.state.chartData.map(d => dateFormat(d.dataPointDate, "mmmm dd, yyyy HH:MM")) }}
                                    yAxisTitle={Utils.getMetricName(this.props.metric)}
                                    title={Utils.getMetricName(this.props.metric)} />
                        }
                    </div>
                    <div className={"form-group mt-4 col-md-4"}>
                        <FilterSection fetch={this.fetch} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Chart;


