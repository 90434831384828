import React, { Component } from 'react';
import GraphQLClient from "../Components/Api/GraphQLClient";
import BatchesFiltersModal from "../Components/Batches/BatchesFiltersModal";
import BatchesQuery from "../Components/GraphQLQueries/BatchesQuery";
import dateFormat from 'dateformat';
import { DataGrid } from '@mui/x-data-grid';
import QuickSearchBox from '../Components/Common/QuickSearchBox';
import LogoutButton from '../Components/Auth/LogoutButton';
import { CSVLink } from 'react-csv';
import LoadingCircle from '../Components/Common/LoadingCircle';
import AuthService from "../Components/Auth/AuthService";
import { convertToLocalDate } from '../Components/Common/Utils';

class Batches extends Component {
    constructor(props) {
        super(props);

        this.graphQlClient = null;

        this.state = {
            batches: [],
            totalNumberOfBatches: 0,
            page: 0,
            sizePerPage: 10,
            showFiltersModal: false,
            loading: true,
        };

        this.filters = {
            offset: 0,
            limit: this.state.sizePerPage,
            batch_id: null,
            batch_status: null,
            batches_time_range_start: null,
            batches_time_range_end: null
        }

        this.loadData = this.loadData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
        this.handleAdvancedSearchClick = this.handleAdvancedSearchClick.bind(this);
        this.handleQuickSearchButtonClicked = this.handleQuickSearchButtonClicked.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.hideFiltersModal = this.hideFiltersModal.bind(this);
    }

    componentDidMount() {
        const { getAccessToken } = AuthService();

        const accessToken = getAccessToken();

        this.graphQlClient = GraphQLClient(accessToken);
        this.loadData(0, this.state.sizePerPage);
    }

    loadData(page, sizePerPage) {
        this.filters = {
            ...this.filters,
            offset: page * sizePerPage,
            limit: sizePerPage,
        }

        this.setState({ loading: true });
        this.graphQlClient.client.query({
            query: BatchesQuery,
            variables: this.filters,
        }).then(result => {
            this.error = result.error;
            this.setState({
                loading: result.loading,
                page: page,
                sizePerPage: sizePerPage,
                batches: result.data.batchesPage.batches,
                totalNumberOfBatches: result.data.batchesPage.totalNumberOfBatches,
            });
        });
    }

    handlePageChange(newPage) {
        setTimeout(() => {
            this.loadData(newPage, this.state.sizePerPage);
        }, 100);
    }

    handlePageSizeChange(newPageSize) {
        this.setState({ sizePerPage: newPageSize })
        this.loadData(0, newPageSize);
    }


    handleSearchButtonClick(filters) {
        this.filters = {
            ...this.filters,
            ...filters,
        }
        this.hideFiltersModal();
        this.loadData(0, this.state.sizePerPage);
    }

    loadRows() {
        return this.state.batches.map((row, index) => {
            return { id: index, ...row };
        });
    }

    renderHeader(headerName) {
        return (
            <div className='header-hover mt-3'>
                <p>{headerName}</p>
            </div>
        );
    }

    clearFilters() {
        this.filters = {
            offset: 0,
            limit: this.state.sizePerPage,
        }
    }

    handleQuickSearchButtonClicked(searchText) {
        this.clearFilters();
        this.filters.batch_id = +searchText;
        this.handleSearchButtonClick({});
    }

    handleAdvancedSearchClick() {
        this.setState({
            showFiltersModal: !this.state.showFiltersModal,
        });
    }

    hideFiltersModal() {
        this.setState({
            showFiltersModal: false,
        });
    }

    getDataToBeExported() {
        let data = JSON.parse(JSON.stringify(this.state.batches));
        data.forEach(row => {
            delete row['__typename'];
        });

        return data;
    }

    render() {
        return (
            <div className={"p-4"}>
                <div className='mt-4 row'>
                    <div className='col-md-9'>
                        <div className='main-box pl-2'>
                            <QuickSearchBox onSearchButtonClicked={this.handleQuickSearchButtonClicked} onAdvancedSearchClick={this.handleAdvancedSearchClick} />
                        </div>
                    </div>
                    <div className='col-md-3 mt-2 pt-1'>
                        <LogoutButton />
                    </div>
                </div>

                <BatchesFiltersModal show={this.state.showFiltersModal} handleSearchButtonClick={this.handleSearchButtonClick} onEscapeKeyPress={this.hideFiltersModal} />

                {
                    this.state.loading ? <div className='mt-20'><LoadingCircle /></div>
                        : <div className={"mt-4 main-box p-4"}>
                            <div className="row pt-2">
                                <div className="col-lg-12 row">
                                    <div className='col'>
                                        <h4><strong>Search Result</strong></h4>
                                    </div>
                                    <div className='ml-auto'>
                                        <CSVLink className="primary-color" data={this.getDataToBeExported()} filename='transfers.csv'>Export as CSV</CSVLink>
                                    </div>
                                </div>
                            </div>
                            {
                                !(this.state.loading || this.error) &&
                                <DataGrid
                                    className='main-box mt-4'
                                    rowCount={this.state.totalNumberOfBatches}
                                    pageSize={this.state.sizePerPage}
                                    page={this.state.page}
                                    onPageChange={this.handlePageChange}
                                    onPageSizeChange={this.handlePageSizeChange}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    pagination
                                    autoHeight
                                    rows={this.loadRows()}
                                    paginationMode={'server'}
                                    columns={[
                                        { field: 'batchId', headerName: 'Batch ID', width: 160, renderHeader: (_) => this.renderHeader('Batch ID') },
                                        {
                                            field: 'batchSettlementDate', headerName: 'Transfer Date', width: 200,
                                            renderHeader: (_) => this.renderHeader('Transfer Date'),
                                            renderCell: (param) => dateFormat(param.value, "mmmm dd, yyyy HH:MM"),
                                        },
                                        {
                                            field: 'batchStartDateTimeUtc', headerName: 'Transactions Start Time', width: 200,
                                            renderHeader: (_) => this.renderHeader('Transactions Start Time'),
                                            renderCell: (param) => dateFormat(convertToLocalDate(param.value), "mmmm dd, yyyy HH:MM"),
                                        },
                                        {
                                            field: 'batchEndDateTimeUtc', headerName: 'Transactions End Time', width: 200,
                                            renderHeader: (_) => this.renderHeader('Transactions End Time'),
                                            renderCell: (param) => dateFormat(convertToLocalDate(param.value), "mmmm dd, yyyy HH:MM"),
                                        },
                                        {
                                            field: 'totalPaidAmount', headerName: 'Total Amount', width: 150,
                                            renderHeader: (_) => this.renderHeader('Total Amount'),
                                            renderCell: (param) => param.value.toFixed(2),
                                        },
                                        {
                                            field: 'dueAmount', headerName: 'Transfer Amount', width: 150,
                                            renderHeader: (_) => this.renderHeader('Transfer Amount'),
                                            renderCell: (param) => param.value.toFixed(2),
                                        },
                                        {
                                            field: 'totalCommission', headerName: 'Total Commission', width: 150,
                                            renderHeader: (_) => this.renderHeader('Total Commission'),
                                            renderCell: (param) => param.value.toFixed(2),
                                        },
                                        {
                                            field: 'batchStatus', headerName: 'Status', width: 130,
                                            renderHeader: (_) => this.renderHeader('Status'),
                                        },
                                        {
                                            field: 'totalNumberOfTransactions', headerName: 'Transactions Count', width: 200,
                                            renderHeader: (_) => this.renderHeader('Transactions Count'),
                                        },
                                    ]} />
                            }
                        </div>
                }
            </div>
        );
    }
}

export default Batches;