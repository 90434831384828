const ThemeUtils = {
    getPrimaryColor() {
        return this.isSahlDomain() ? '#4055FF' : '#85BB65'
    },
    getTitleOrLogo() {
        return this.isSahlDomain() ? <img alt="sahl-logo"
            src="https://public-assets-prod-eu-west-1.s3.eu-west-1.amazonaws.com/images/sahl-v2.png"
            className="sahl-logo" /> : 'TRXACT';
    },
    isSahlDomain() {
        return window.location.href.includes('sahl');
    },
    getSDKScriptUrl() {
        return this.isSahlDomain() ? "https://sdk.acceptance.sahlpay.app" : "https://sdk.trxact.io";
    },
    getReactNativePackageName() {
        return this.isSahlDomain() ? "sahl-acceptance" : "trxact";
    },
    getReactNativeComponentName() {
        return this.isSahlDomain() ? "UseSahlAcceptance" : "UseTrxact";
    }
}

export default ThemeUtils;