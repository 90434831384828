import React from "react";
import { Modal } from 'react-bootstrap'
import Icons from "../Common/Icons";

const roles = ["Admin", "TechOps", "Dev", "Finance"];

class UserCreationCardModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
            name: null,
            role: roles[0],
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.onAddUser = this.onAddUser.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value,
        });
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value,
        });
    }

    handleRoleChange(event) {
        this.setState({
            role: event.target.value,
        });
    }

    close = () => {
        this.resetState();
        this.props.onEscapeKeyPress();
    }

    resetState = () => {
        this.setState({
            customer_id: null,
            merchant_transaction_reference: null,
            system_transaction_reference: null,
            payment_method_type: null,
            transaction_status: null,
            transactions_time_range_start: null,
            transactions_time_range_end: null
        });
    }

    onAddUser() {
        this.props.createUser(this.state);
    }

    render() {
        return (
            <Modal show={this.props.show} size="lg">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />,
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 p-4">
                        <div className={"form-row"}>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Email</label>
                                <input type={"email"} className={"form-control"} required onChange={this.handleEmailChange} />
                            </div>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Name</label>
                                <input type={"text"} className={"form-control"} required onChange={this.handleNameChange} />
                            </div>
                            <div className={"form-group col-md-4"}>
                                <label className={"col-form-label"}>Role</label>
                                <select className={"form-control"} onChange={this.handleRoleChange}>
                                    {
                                        roles.map((option) => {
                                            return <option key={option} value={option}>{option}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <button
                                onClick={this.onAddUser}
                                className="btn btn-primary"> Add User
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default UserCreationCardModal;