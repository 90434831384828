import jwt_decode from "jwt-decode";
function PermissionProvider()
{
    let userPermissions = null;
    const getUserPermissions = (accessToken) =>
    {
        const decodedAccessToken =  jwt_decode(accessToken);
        return decodedAccessToken.permissions;
    }

    const DoesUserHaveThePermission = (permission, accessToken) =>
    {
        if (accessToken == null)
            return false;
        if (userPermissions == null)
            userPermissions = getUserPermissions(accessToken);
        return userPermissions.includes(permission);
    }

    return {
        DoesUserHaveThePermission: DoesUserHaveThePermission,
    }
}

export default PermissionProvider;
