import React, {Component} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormGroup,
    List,
    ListItem,
    ListItemText
} from "@mui/material";

class MerchantInfoPreviewPage extends Component {
    constructor(props) {
        super(props);
        
        this.back = this.back.bind(this);
        this.submit = this.submit.bind(this);
    }
    
    back(){
        this.props.handlePrevious();
    }
    
    submit(){
        this.props.onSubmit();
    }
    
    render() {
        return (
            <FormGroup>
                <FormControl>
                    <List sx={{ width: '70%', alignSelf: "center"}}>
                        <ListItem divider={true}>
                            <ListItemText
                                primary={"Company Name"}
                                secondary={this.props.values.company_name}
                            />
                        </ListItem>
                        <ListItem divider={true}>
                            <ListItemText
                                primary={"Company Address"}
                                secondary={this.props.values.company_address}
                            />
                        </ListItem>
                        <ListItem divider={true}>
                            <ListItemText
                                primary={"Tax Card"}
                                secondary={
                                    <img style={{width: '50%', height: 200}} src={URL.createObjectURL(this.props.values.tax_card_image)} alt={"Tax Card"} />
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={"Commercial Register"}
                                secondary={
                                    <img style={{width: '50%', height: 200}} src={URL.createObjectURL(this.props.values.commercial_register_image)} alt={"Commercial Register"} />
                                }
                            />
                        </ListItem>
                    </List>
                </FormControl>
                
                <FormControl>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Button onClick={this.back} sx={{ mr: 1 }}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={this.submit}
                        >
                            Submit
                        </Button>
                    </Box>
                </FormControl>
            </FormGroup>
        );
    }
}

export default MerchantInfoPreviewPage;