import React, { Component } from 'react';
import Icons from '../Components/Common/Icons';
import { IntegrationGuideData } from './IntegrationGuideData';
import ThemeUtils from '../Components/Common/ThemeUtils';

class IntegrationGuide extends Component {

    constructor(props) {
        super(props);
        this.getAnAccessTokenCurl = IntegrationGuideData.getAnAccessTokenCurl(process.env.REACT_APP_API_GATEWAY_BASE_DOMAIN);
        this.initTransactionSessionCurl = IntegrationGuideData.initTransactionSessionCurl(process.env.REACT_APP_API_GATEWAY_BASE_DOMAIN);
        this.getCustomerTokenCurl = IntegrationGuideData.getCustomerTokenCurl(process.env.REACT_APP_API_GATEWAY_BASE_DOMAIN);
    }

    copy(text) {
        navigator.clipboard.writeText(text);
    }

    renderCodeSection(text) {
        return (<div className='pt-3 ml-2 pr-3 code-box row'>
            <div className='col-12'>
                <div className="copy-icon-box" onClick={() => { this.copy(text) }}>
                    <Icons.CopyIcon />
                </div>
            </div>
            <pre className='mt-4 pt-4 pl-3 pb-2 code-box'>
                <code className='mt-1'>
                    {text}
                </code>
            </pre>
        </div>);
    }

    renderWebsiteIntegrationSection() {
        return (
            <div className='mt-4 pt-4 ml-2'>
                <div className='col-12'>
                    <h4 className='title-color'>Website integration</h4>
                </div>
                <br />
                <div className='col-12'>
                    <p>For website integration you need to do the following steps:</p>
                    <p className='mt-4 integration-step-color'>1.1 Get an access token.</p>
                    {this.renderCodeSection(this.getAnAccessTokenCurl)}
                    <p className='mt-4 integration-step-color'>1.2 The response from <strong>step 1.1</strong> will include an <strong>access_token</strong> and an <strong>expires_in</strong> fields.</p>
                    {this.renderCodeSection(IntegrationGuideData.getAnAccessTokenResponse)}
                    <p className='mt-4 integration-step-color'>2. We expect the request hash to be sent as an http header. This is currently enforced on all POST apis.</p>
                    {this.renderCodeSection(IntegrationGuideData.requestHashText)}
                    <p className='mt-4 integration-step-color'>3.1 Making a transaction: Initiate transaction session.</p>
                    {this.renderCodeSection(this.initTransactionSessionCurl)}
                    <p className='mt-4 integration-step-color'>3.2 The response from <strong>step 3.1</strong> will include a <strong>session_url</strong> field.</p>
                    {this.renderCodeSection(IntegrationGuideData.initTransactionSessionResponse)}
                    <p className='mt-4 integration-step-color'>4.1 Get customer token.</p>
                    {this.renderCodeSection(this.getCustomerTokenCurl)}
                    <p className='mt-4 integration-step-color'>4.2 The response from <strong>step 4.1</strong> will include a <strong>token</strong> field.</p>
                    {this.renderCodeSection(IntegrationGuideData.getCustomerTokenResponse)}
                    <br />
                    <p className='mt-4 integration-step-color'>5. Replace <strong>"[token]"</strong> of the <strong>session_url</strong>(which you got from step 3) with the customer token (which you got from step 4) and then you can redirect your customers to this url to start the payment flow.</p>

                </div>
                <br />
            </div>
        );
    }

    renderMobileIntegrationSection() {
        return (
            <div className='mt-4 pt-4 ml-2'>
                <div className='col-12'>
                    <h4 className='title-color'>Mobile integration</h4>
                </div>
                <br />
                <div className='col-12'>
                    <p>For <strong>react native</strong> you need to do the following steps:</p>
                    <p className='mt-4 integration-step-color'>1. copy and run the following command to install the dependencies</p>
                    {this.renderCodeSection(IntegrationGuideData.reactNativeInstallDependencies)}
                    <p className='mt-4 integration-step-color'>2. copy and run the following command to install the package</p>
                    {this.renderCodeSection(IntegrationGuideData.reactNativeInstallPackage(ThemeUtils.getReactNativePackageName()))}
                </div>
                <br />
                <div className=' mt-4 col-12'>
                    <h4 className='title-color'>Usage</h4>
                </div>
                <div className='col-12 mt-4'>
                    {this.renderCodeSection(IntegrationGuideData.reactNativeUsage(ThemeUtils.getReactNativePackageName(), ThemeUtils.getReactNativeComponentName()))}
                </div>
            </div>
        );
    }

    renderApiIntegrationSection() {
        return (
            <div className='mt-4 pt-4 ml-2'>
                <div className='col-12'>
                    <h4 className='title-color'>API integration</h4>
                </div>
                <br />
                <div className='col-12'>
                    <p className='mt-4 integration-step-color'>
                        <a href='/assets/api_documentation.pdf' download>Click here to download the API documentation guide</a>
                    </p>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className='mt-4 main-box p-4'>
                <div className='mt-4 pb-4'>
                    <div className='col ml-2'>
                        <h2><strong>Integration Guide</strong></h2>
                    </div>
                </div>
                <hr />
                {this.renderWebsiteIntegrationSection()}
                <br />
                <hr />
                {this.renderMobileIntegrationSection()}
                <br />
                <hr />
                {this.renderApiIntegrationSection()}
                <br />
                <br />
            </div>
        );
    }
}
export default IntegrationGuide;