import React, { Component } from 'react';
import GraphQLClient from "../Components/Api/GraphQLClient";
import UsersQuery from "../Components/GraphQLQueries/UsersQuery";
import DeleteUserMutation from "../Components/GraphQLQueries/DeleteUserMutation";
import CreateUserMutation from "../Components/GraphQLQueries/CreateUserMutation";
import UserCreationCardModal from "../Components/Settings/UserCreationCardModal";
import dateFormat from 'dateformat';
import { DataGrid } from '@mui/x-data-grid';
import AuthService from "../Components/Auth/AuthService";
import { Box, Button } from "@mui/material";
import ApiClient from "../Components/Api/ApiClient";
import MerchantConfigurations from "../Components/Settings/MerchantConfigurations";
import MerchantOnboardingCardModal from "../Components/Settings/MerchantOnboardingCardModal";
import { convertToLocalDate, fileToBase64 } from "../Components/Common/Utils";
import Credentials from "../Components/Settings/Credentials";
import { OnboardingState } from "../Constants";
import LoadingCircle from "../Components/Common/LoadingCircle";

const { getAccessToken } = AuthService();

class Settings extends Component {
    constructor(props) {
        super(props);
        this.graphQlClient = null;

        this.state = {
            loading: false,
            loadingCredentials: false,
            users: [],
            credentials: null,
            configurations: null,
            onboarding_state: null,
            totalNumberOfUsers: 0,
            page: 0,
            sizePerPage: 10,
            isShowUserCreationModal: false,
            isShowMerchantOnboardingModal: false,
        };

        this.filters = {
            offset: 0,
            limit: this.state.sizePerPage
        }

        // API Calls
        this.loadData = this.loadData.bind(this);
        this.fetchCredentials = this.fetchCredentials.bind(this);
        this.fetchMerchantConfigurations = this.fetchMerchantConfigurations.bind(this);
        this.fetchOnboardingState = this.fetchOnboardingState.bind(this);
        this.updateMerchantConfigurations = this.updateMerchantConfigurations.bind(this);
        this.setMerchantConfigurations = this.setMerchantConfigurations.bind(this);
        this.showAppropriateOnboardingModalOrFetchCredentials = this.showAppropriateOnboardingModalOrFetchCredentials.bind(this);
        this.submitMerchantOnboardingData = this.submitMerchantOnboardingData.bind(this);

        // UI Changes
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.hideUserCreationModal = this.hideUserCreationModal.bind(this);
        this.hideMerchantOnboardingModal = this.hideMerchantOnboardingModal.bind(this);

        // Callbacks
        this.createUserCallback = this.createUserCallback.bind(this);
        this.deleteUserCallback = this.deleteUserCallback.bind(this);
        this.onboardingStateSuccessCallback = this.onboardingStateSuccessCallback.bind(this);
        this.fetchCredentialsSuccessCallback = this.fetchCredentialsSuccessCallback.bind(this);
        this.submitMerchantOnboardingDataSuccessCallback = this.submitMerchantOnboardingDataSuccessCallback.bind(this);
        this.merchantConfigurationsSuccessCallback = this.merchantConfigurationsSuccessCallback.bind(this);
        this.ApiFailureCallback = this.ApiFailureCallback.bind(this);
    }

    async componentDidMount() {
        const accessToken = getAccessToken();

        this.graphQlClient = GraphQLClient(accessToken);

        this.setState({ loading: true });

        this.loadData(0, this.state.sizePerPage);

        await this.fetchOnboardingState();
        await this.fetchMerchantConfigurations();

        this.setState({ loading: false });
    }

    loadData(page, sizePerPage) {
        this.filters = {
            offset: page * sizePerPage,
            limit: sizePerPage,
        }

        this.graphQlClient.client.query({
            query: UsersQuery,
            variables: this.filters,
        }).then(result => {
            this.loading = result.loading;
            this.error = result.error;
            this.setState({
                page: page,
                sizePerPage: sizePerPage,
                users: result.data.usersPage.users,
                totalNumberOfTransactions: result.data.usersPage.totalNumberOfUsers,
            });
        });
    }

    onboardingStateSuccessCallback(response) {
        if (response && response.succeeded === true) {
            this.setState({
                onboarding_state: response.onboarding_state,
            });
        } else {
            console.log(response.error_message);
            this.setState({
                onboarding_state: null,
            });
        }
    }

    merchantConfigurationsSuccessCallback(response) {
        if (response && response.succeeded === true) {
            this.setState({
                configurations: response.configurations,
            });
        } else {
            console.log(response.error_message);
        }
    }

    ApiFailureCallback(error) {
        alert("Unexpected error");
    }

    async fetchOnboardingState() {
        const { getOnboardingState } = ApiClient();

        const accessToken = getAccessToken();

        await getOnboardingState(accessToken, this.onboardingStateSuccessCallback, this.ApiFailureCallback);
    }

    async fetchMerchantConfigurations() {
        const { getConfigurations } = ApiClient();

        const accessToken = getAccessToken();

        await getConfigurations(accessToken, this.merchantConfigurationsSuccessCallback, this.ApiFailureCallback)
    }

    async updateMerchantConfigurations(requestDto, successCallback) {
        const { updateConfiguration } = ApiClient();

        const accessToken = getAccessToken();

        await updateConfiguration(accessToken, requestDto, successCallback, this.ApiFailureCallback);
    }

    setMerchantConfigurations(configurations) {
        this.setState({
            configurations: configurations,
        });
    }

    fetchCredentialsSuccessCallback(response) {
        if (response && response.succeeded === true) {
            this.setState({
                credentials: response.credentials,
            });
        } else {
            console.log(response.error_message);
            this.setState({
                credentials: null,
            });
        }
    }

    async fetchCredentials() {
        const { getCredentials } = ApiClient();

        const accessToken = getAccessToken();

        await getCredentials(accessToken, this.fetchCredentialsSuccessCallback, this.ApiFailureCallback);
        await this.fetchOnboardingState();
    }

    async submitMerchantOnboardingData(data) {
        let taxCardImageBase64 = await fileToBase64(data.tax_card_image);
        let commercialRegisterImageBase64 = await fileToBase64(data.commercial_register_image);

        const identityVerificationRequestDto = {
            company_name: data.company_name,
            company_address: data.company_address,
            tax_card_image: {
                file: taxCardImageBase64.split(',')[1],
                file_data_format: "BASE_64",
            },
            commercial_register_image: {
                file: commercialRegisterImageBase64.split(',')[1],
                file_data_format: "BASE_64",
            },
        };

        const { submitIdentityVerificationRequest } = ApiClient();

        const accessToken = getAccessToken();

        await submitIdentityVerificationRequest(accessToken, identityVerificationRequestDto, this.submitMerchantOnboardingDataSuccessCallback, this.ApiFailureCallback);

        await this.fetchOnboardingState();
        this.hideMerchantOnboardingModal();
    }

    submitMerchantOnboardingDataSuccessCallback(response) {
        if (response && response.succeeded === true) {
            console.log(response.case_id);
        } else {
            alert("Failed to submit onboarding data");
        }
    }

    createUserCallback(filters) {
        this.graphQlClient.client.mutate({
            mutation: CreateUserMutation,
            variables: {
                email: filters.email,
                name: filters.name,
                role: filters.role,
            },
        }).then(result => {
            alert("Password: " + result.data.createUser.password);
            this.loadData(this.state.page, this.state.sizePerPage)
        });

        this.hideUserCreationModal();
    }

    deleteUserCallback(id) {
        this.graphQlClient.client.mutate({
            mutation: DeleteUserMutation,
            variables: {
                userId: id,
            },
        }).then(result => {
            alert(result.data.deleteUser);
            this.loadData(this.state.page, this.state.sizePerPage)
        });
    }

    handlePageChange(newPage) {
        setTimeout(() => {
            this.loadData(newPage, this.state.sizePerPage);
        }, 100);
    }

    handlePageSizeChange(newPageSize) {
        this.setState({ sizePerPage: newPageSize })
        this.loadData(0, newPageSize);
    }

    renderDeleteButton(userId) {
        return (
            <div className='text-center ml-auto'>
                <button className=' btn btn-danger' onClick={() => this.deleteUserCallback(userId)}>Delete</button>
            </div>);
    }

    loadRows() {
        return this.state.users.map((row, index) => { return { id: index, ...row } });
    }

    renderHeader(headerName) {
        return (
            <div className='header-hover mt-3'>
                <p>{headerName}</p>
            </div>
        );
    }

    hideUserCreationModal() {
        this.setState({
            isShowUserCreationModal: false
        });
    }

    hideMerchantOnboardingModal() {
        this.setState({
            isShowMerchantOnboardingModal: false
        });
    }

    async showAppropriateOnboardingModalOrFetchCredentials() {
        if (this.state.onboarding_state) {
            if (this.state.onboarding_state === OnboardingState.acceptedReview || this.state.onboarding_state === OnboardingState.onboardingComplete) {
                this.setState({ loadingCredentials: true });
                await this.fetchCredentials();
                this.setState({ loadingCredentials: false });
            } else if (this.state.onboarding_state === OnboardingState.onboardingFailed || this.state.onboarding_state === OnboardingState.notInitiated) {
                this.setState({
                    isShowMerchantOnboardingModal: true,
                });
            } else {
                alert("In review");
            }
        }
    }

    render() {
        return (
            <div className='main-box mt-4 p-4'>
                <div className='mt-4 col-12 row'>
                    <button className='btn btn-primary ml-auto' onClick={() => this.setState({ isShowUserCreationModal: true })}>Add User</button>
                </div>
                <div className={"mt-4 main-box"}>
                    {
                        !(this.loading || this.error) &&
                        <DataGrid
                            className='main-box mt-4'
                            rowCount={this.state.totalNumberOfUsers}
                            pageSize={this.state.sizePerPage}
                            page={this.state.page}
                            onPageChange={this.handlePageChange}
                            onPageSizeChange={this.handlePageSizeChange}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            pagination
                            autoHeight
                            rows={this.loadRows()}
                            paginationMode={'server'}
                            columns={[
                                { field: 'id', headerName: 'User ID', width: 200, renderHeader: (_) => this.renderHeader('User ID') },
                                { field: 'email', headerName: 'Email', width: 200, renderHeader: (_) => this.renderHeader('Email') },
                                { field: 'name', headerName: 'Name', width: 200, renderHeader: (_) => this.renderHeader('Name') },
                                { field: 'role', headerName: 'Role', width: 130, renderHeader: (_) => this.renderHeader('Role'), },
                                {
                                    field: 'lastLogin', headerName: 'Last Login', width: 180,
                                    renderHeader: (_) => this.renderHeader('Last Login'),
                                    renderCell: (param) => { if (param == null || param.value == null) return ""; else dateFormat(convertToLocalDate(param.value), "mmmm dd, yyyy HH:MM") },
                                },
                                {
                                    field: 'delete', headerName: '', width: 130, renderHeader: (_) => this.renderHeader(''), renderCell: (param) => {
                                        return this.renderDeleteButton(param.row['id']);
                                    }
                                },
                            ]} />
                    }
                </div>
                <br />
                <br />
                {
                    this.state.loading ? <LoadingCircle /> :
                        <Box
                            sx={{
                                p: 1,
                                m: 1
                            }}
                        >
                            <div className="mt-4">
                                <h4><strong>API Integration</strong></h4>
                            </div>
                            {
                                this.state.credentials &&
                                <Credentials credentials={this.state.credentials} />
                            }
                            {
                                this.state.loadingCredentials ? <LoadingCircle width={40} height={40} /> :
                                    <Button onClick={this.showAppropriateOnboardingModalOrFetchCredentials}>Fetch Credentials</Button>
                            }
                        </Box>
                }

                <br />
                <br />
                {
                    this.state.loading ? <LoadingCircle /> :
                        <MerchantConfigurations
                            canEdit={this.state.onboarding_state === OnboardingState.onboardingComplete}
                            configurations={this.state.configurations}
                            updateMerchantConfigurations={this.updateMerchantConfigurations}
                            setMerchantConfigurations={this.setMerchantConfigurations}
                        />
                }

                <UserCreationCardModal show={this.state.isShowUserCreationModal} createUser={this.createUserCallback} onEscapeKeyPress={this.hideUserCreationModal} />
                <MerchantOnboardingCardModal show={this.state.isShowMerchantOnboardingModal} onOnboardingFormSubmit={this.submitMerchantOnboardingData} onEscapeKeyPress={this.hideMerchantOnboardingModal} />
            </div>

        );
    }
}
export default Settings;