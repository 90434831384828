import React from "react";
import ReactApexChart from "react-apexcharts";
import ThemeUtils from "../Common/ThemeUtils";

export default function AreaChart(props) {
    return (
        <div id="chart">
            <ReactApexChart options={{
                colors: [ThemeUtils.getPrimaryColor()],
                chart: {
                    type: 'area',
                    stacked: false,
                    height: 'auto',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        autoSelected: 'zoom'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    colors: [ThemeUtils.getPrimaryColor()]
                },
                title: {
                    text: props.title,
                    align: 'left'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100],
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(2);
                        },
                    },
                    title: {
                        text: props.yAxisTitle
                    },
                },
                xaxis: {
                    type: props.xAxis.type,
                    categories: props.xAxis.categories
                },
                tooltip: {
                    shared: false,
                    y: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                }
            }}
                series={[{
                    name: props.series.name,
                    data: props.series.data
                }]} type="area" height={'auto'} />
        </div>
    );
}
