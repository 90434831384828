import {Component} from "react";
import {Container, Divider, Grid} from "@mui/material";
import Logo from "../Components/Common/Logo";
import LoginForm from "../Components/Auth/LoginForm";

class Login extends Component{
    render() {
        return <Container maxWidth={"xl"} sx={{ bgcolor: '#ffffff', margin: '50px', borderRadius: '1%'}}>
            <Grid container alignItems="center" sx={{height: '750px'}}>
                <Grid item xs={6} >
                    <Logo />
                </Grid>
                <Divider orientation="vertical" variant={"middle"} flexItem />
                <Grid item xs={5} >
                    <LoginForm />
                </Grid>
            </Grid>
        </Container>
    }
}

export default Login;