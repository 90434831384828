import React from "react";
import {Modal} from 'react-bootstrap'
import Icons from "../Common/Icons";
import MerchantDetails from "./MerchantDetails";
import MerchantInfoPreviewPage from "./MerchantInfoPreviewPage";
import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import TaxCardImageUpload from "./TaxCardImageUpload";
import CommercialRegisterImageUpload from "./CommercialRegisterImageUpload";
import LoadingCircle from "../Common/LoadingCircle";

const pagesLabels = [
    "Company Details",
    "Tax Card Image",
    "Commercial Register Image",
    "Preview"
];

class MerchantOnboardingCardModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            company_name: '',
            company_address: '',
            tax_card_image: null,
            commercial_register_image: null,
            loading: false,
        };
        
        this.escFunction = this.escFunction.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        
        this.updateMerchantDetails = this.updateMerchantDetails.bind(this);
        this.updateTaxCardImage = this.updateTaxCardImage.bind(this);
        this.updateCommercialRegister = this.updateCommercialRegister.bind(this);
        
        this.handleAppropriateComponent = this.handleAppropriateComponent.bind(this);
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
        this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
        
        this.getMerchantDetails = this.getMerchantDetails.bind(this);
        this.getTaxCardImage = this.getTaxCardImage.bind(this);
        this.getCommercialRegisterImage = this.getCommercialRegisterImage.bind(this);
        this.getPreviewInfo = this.getPreviewInfo.bind(this);
    }
    
    getMerchantDetails(){
        return {
            company_name: this.state.company_name,
            company_address: this.state.company_address,
        };
    }
    
    getTaxCardImage(){
        return {
            image: this.state.tax_card_image,
        };
    }

    getCommercialRegisterImage(){
        return {
            image: this.state.commercial_register_image,
        };
    }
    
    getPreviewInfo(){
        return {
            company_name: this.state.company_name,
            company_address: this.state.company_address,
            tax_card_image: this.state.tax_card_image,
            commercial_register_image: this.state.commercial_register_image,
        };
    }
    
    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    close = () => {
        this.props.onEscapeKeyPress();
    }
    
    async onSubmit(){
        this.setState({loading: true,});
        await this.props.onOnboardingFormSubmit(this.getPreviewInfo());
        this.setState({loading: false,});
    }
    
    updateMerchantDetails(values){
        this.setState({
            company_name: values.company_name,
            company_address: values.company_address,
        });
    }

    updateTaxCardImage(tax_card_image){
        this.setState({
            tax_card_image: tax_card_image,
        });
    }
    
    updateCommercialRegister(commercial_register_image){
        this.setState({
            commercial_register_image: commercial_register_image,
        });
    }
    
    handleNextButtonClick(){
        this.setState({step: Math.min(this.state.step + 1, pagesLabels.length - 1)});
    }

    handlePreviousButtonClick(){
        this.setState({step: Math.max(this.state.step - 1, 0)});
    }
    
    handleAppropriateComponent(){
        switch (this.state.step){
            case 0:
                return (
                    <MerchantDetails
                        initialValues={this.getMerchantDetails()}
                        submitAction={this.updateMerchantDetails}
                        handleNext={this.handleNextButtonClick} />
                );
            case 1:
                return (
                    <TaxCardImageUpload
                        label={"TaxCard"}
                        initialValue={this.getTaxCardImage()}
                        submitAction={this.updateTaxCardImage}
                        handleNext={this.handleNextButtonClick}
                        handlePrevious={this.handlePreviousButtonClick} />
                );
            case 2:
                return (
                    <CommercialRegisterImageUpload
                        label={"CommercialRegister"}
                        initialValue={this.getCommercialRegisterImage()}
                        submitAction={this.updateCommercialRegister}
                        handleNext={this.handleNextButtonClick}
                        handlePrevious={this.handlePreviousButtonClick} />
                );
            case 3:
                return (
                    <MerchantInfoPreviewPage
                        values={this.getPreviewInfo()}
                        handlePrevious={this.handlePreviousButtonClick}
                        onSubmit={this.onSubmit}/>
                );
            default:
                return null;
        }
    }
    
    render() {
        return (
            <Modal show={this.props.show} size="lg">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Typography variant="h4" align="center">
                        Merchant Onboarding
                    </Typography>
                    {
                        this.state.loading ? <LoadingCircle /> :
                        <>
                            <Stepper activeStep={this.state.step} sx={{ py: 3 }} alternativeLabel>
                                {pagesLabels.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            {
                                this.handleAppropriateComponent()
                            }
                        </>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

export default MerchantOnboardingCardModal;