import { gql } from '@apollo/client';

const UsersQuery = gql
    `query UsersQuery(
        $offset: Int,
        $limit: Int
        ){
        usersPage(
            offset: $offset,
            limit: $limit)
        {
            totalNumberOfUsers
            users{
              id
              name
              email
              role
              lastLogin
            }
        }
    }`;

export default UsersQuery;