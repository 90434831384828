import React, {Component} from 'react';
import {Box, Button, Fade, FormControl, FormGroup} from "@mui/material";

class ImageUpload extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            image: this.props.initialValue.image,
        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.isError = this.isError.bind(this);
        this.submit = this.submit.bind(this);
        this.back = this.back.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    isError(){
        return !this.state.image;
    }

    submit(){
        this.props.submitAction(this.state.image);
        this.props.handleNext();
    }
    
    back(){
        this.props.handlePrevious();
    }

    getLabel(){
        return this.props.label;
    }
    
    handleFileChange(e){
        if(e.target.files.length > 0){
            this.setState({image: e.target.files[0]});
        }
    }
    
    render() {
        return (
            <FormGroup>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                    <label htmlFor={this.getLabel() + "_id"}>
                        <Button variant={"outlined"} component={"span"}>
                            Upload Image
                        </Button>
                    </label>
                    <input
                        id={this.getLabel() + "_id"}
                        type={"file"}
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={this.handleFileChange}
                    />
                </Box>
                
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    {
                        this.state.image &&
                        <Fade in={true} timeout={1000}>
                            <Box
                                component="img"
                                sx={{
                                    height: "20%",
                                    width: 350,
                                }}
                                alt={this.getLabel()}
                                src={URL.createObjectURL(this.state.image)}
                            />
                        </Fade>
                    }
                </Box>
                
                <FormControl>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                        <Button onClick={this.back} sx={{ mr: 1 }}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            disabled={this.isError()}
                            color="primary"
                            onClick={!this.isError() ? this.submit : () => null}
                        >
                            Next
                        </Button>
                    </Box>
                </FormControl>
            </FormGroup>
        );
    }
}

export default ImageUpload;