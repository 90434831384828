const ColorPallete = {
    grey: 'grey',
    white: '#fff',
    red: '#FF0000',
    lightGreen: '#34C759',
    cyan: 'cyan',
    brown: '#E7B400',
    DarkOrange: '#E35335',
    DarkYellow: '#F0E68C',
    lightGrey: '#f5f5f5',
}

export default ColorPallete;