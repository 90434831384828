export const fileToBase64 = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {

        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Problem parsing input file."));
        };
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result);
    });
}

export const isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const convertToLocalDate = (dateStr) => {
    if(!dateStr.endsWith('Z')) dateStr += 'Z';
    return new Date(dateStr);
}