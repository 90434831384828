import React from "react";
import { NavLink } from "react-router-dom";

import "./Navbar.css";

import ThemeUtils from "../Common/ThemeUtils";
import AuthService from "../Auth/AuthService";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import RolePermissions from "../PermissionProvider/RolePermissions";
import NavbarItem from "./NavbarItem";
import { SidebarItems } from "./SidebarItems";

export default function Navbar() {
  const { isAuthenticated, getAccessToken } = AuthService();
  const { DoesUserHaveThePermission } = PermissionProvider();
  const accessToken = getAccessToken();

  let hasPermissionToHomePage = false;
  let hasPermissionToTransactionsPage = false;
  let hasPermissionToTransfersPage = false;
  let hasPermissionToSettingsPage = false;
  let hasPermissionToIntegrationGuide = false;
  let hasPermissionToLiveLinks = false;

  RolePermissions.statisticsPagePermissions.forEach(permission => hasPermissionToHomePage = hasPermissionToHomePage || DoesUserHaveThePermission(permission, accessToken));
  RolePermissions.transactionsPagePermissions.forEach(permission => hasPermissionToTransactionsPage = hasPermissionToTransactionsPage || DoesUserHaveThePermission(permission, accessToken));
  RolePermissions.transfersPagePermissions.forEach(permission => hasPermissionToTransfersPage = hasPermissionToTransfersPage || DoesUserHaveThePermission(permission, accessToken));
  RolePermissions.settingsPagePermissions.forEach(permission => hasPermissionToSettingsPage = hasPermissionToSettingsPage || DoesUserHaveThePermission(permission, accessToken));
  RolePermissions.integrationGuidePermissions.forEach(permission => hasPermissionToIntegrationGuide = hasPermissionToIntegrationGuide || DoesUserHaveThePermission(permission, accessToken));
  RolePermissions.liveLinksPermissions.forEach(permission => hasPermissionToLiveLinks = hasPermissionToLiveLinks || DoesUserHaveThePermission(permission, accessToken));

  return (
    isAuthenticated() &&
    <>
      <nav className="nav-menu active pt-4">
        <ul className="nav-menu-items pl-3">
          <li key={0} className="nav-text mt-4">
            <div>
              <NavLink to={"/"}>
                <h4 className="nav-title primary-color"><strong>{ThemeUtils.getTitleOrLogo()}</strong></h4>
              </NavLink>
              <div className="ml-3"><span className="title-color">Acceptance</span> <span className="subtitle-color">Dashboard</span></div>
            </div>
          </li>
          <hr />

          {
            hasPermissionToHomePage &&
            <NavbarItem index={1} item={SidebarItems.Home} />
          }
          {
            hasPermissionToTransactionsPage &&
            <NavbarItem index={2} item={SidebarItems.Transactions} />
          }
          {
            hasPermissionToTransfersPage &&
            <NavbarItem index={3} item={SidebarItems.Transfers} />
          }

          {
            hasPermissionToLiveLinks &&
            <NavbarItem index={4} item={SidebarItems.LiveLinks} />
          }

          <NavbarItem index={5} item={SidebarItems.Hr} />

          {
            hasPermissionToSettingsPage &&
            <>
              <NavbarItem index={6} item={SidebarItems.Settings} />
              <NavbarItem index={7} item={SidebarItems.Hr} />
            </>
          }

          {
            hasPermissionToIntegrationGuide &&
            <NavbarItem index={8} item={SidebarItems.Integration} />
          }
        </ul>
      </nav>
    </>
  );
}
