import DateInputField from "../Common/DateInputField";
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'
import Icons from "../Common/Icons";

const batchStatus = {
    "All": null,
    "InProgress": 1,
    "Completed": 2,
};

class BatchesFiltersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            batch_id: null,
            batch_status: null,
            batches_time_range_start: null,
            batches_time_range_end: null
        }

        this.handleBatchIDChange = this.handleBatchIDChange.bind(this);
        this.handleBatchStatusChange = this.handleBatchStatusChange.bind(this);
        this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
        this.handleStartRangeDateChange = this.handleStartRangeDateChange.bind(this);
        this.handleEndRangeDateChange = this.handleEndRangeDateChange.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    handleBatchIDChange(event) {
        this.setState({
            batch_id: parseInt(event.target.value),
        });
    }
    handleBatchStatusChange(event) {
        this.setState({
            batch_status: parseInt(event.target.value),
        });
    }

    handleStartRangeDateChange(date) {
        this.setState({
            batches_time_range_start: date,
        });
    }

    handleEndRangeDateChange(date) {
        this.setState({
            batches_time_range_end: date,
        });
    }

    handleSearchButtonClick() {
        this.props.handleSearchButtonClick(this.state);
        this.resetState();
    }

    close = () => {
        this.resetState();
        this.props.onEscapeKeyPress();
    }

    resetState = () => {
        this.setState({
            batch_id: null,
            batch_status: null,
            batches_time_range_start: null,
            batches_time_range_end: null
        });
    }

    render() {
        return (
            <Modal show={this.props.show} size="lg">
                <Modal.Header>
                    <div className="ml-auto" style={{ cursor: 'pointer' }} onClick={this.close}>
                        <Icons.CloseIcon />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-4">
                        <div className="row">
                            <div className="col-lg-10">
                                <h4><strong>Search</strong></h4>
                            </div>
                            <div className="col-lg-2">
                                <button className={"btn btn-block btn-primary"} onClick={this.handleSearchButtonClick}>Search</button>
                            </div>
                        </div>
                        <div className={"form-row mt-4"}>
                            <div className={"form-group col-md-6"}>
                                <label className={"col-form-label"}>Batch ID</label>
                                <input type={"text"} className={"form-control"} onChange={this.handleBatchIDChange} />
                            </div>
                            <div className={"form-group col-md-6"}>
                                <label className={"col-form-label"}>Batch Status</label>
                                <select className={"form-control"} onChange={this.handleBatchStatusChange}>
                                    {
                                        Object.keys(batchStatus).map((key, index) => {
                                            return <option key={index} value={batchStatus[key]}>{key}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-row">
                            <label className={"col-form-label"}>Time</label>
                        </div>
                        <div className={"form-row"}>
                            <div className={"form-group col-md-6 date-picker"}>
                                <DateInputField setDate={this.handleStartRangeDateChange} inputFormat={DateInputField.InputFormate.DateFormat} label="From:" max={this.state.batches_time_range_end} />
                            </div>
                            <div className={"form-group col-md-6 date-picker"}>
                                <DateInputField setDate={this.handleEndRangeDateChange} inputFormat={DateInputField.InputFormate.DateFormat} label="To:" min={this.state.batches_time_range_start} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }
}

export default BatchesFiltersModal;