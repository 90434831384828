import { gql } from '@apollo/client';

const CreateUserMutation = gql
    `mutation CreateUser($email: String!, $name: String!, $role: String!){
        createUser(email: $email, name: $name, role: $role){
            userId
            email
            password
        }
    }`;

export default CreateUserMutation;